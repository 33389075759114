const TemplateEditor = Vue.component('TemplateEditor', {
  data() {
    return {
      title: '',
      creating: false,
      editTemplate: {canned_response: '', key: '', department_ids: []},
      filterDeps: [],
      keyMaxLength: 100, // arbitrario, ni idea porque tenemos esta validacion
      templateMaxLength: 3000
    };
  },
  computed: {
    ...Vuex.mapGetters(['template', 'departments', 'userIsSupervisor']),
    disabledForm() {
      const {key, canned_response} = this.editTemplate;
      if(!key){
        console.error("key is empty", key);
        return true;
      }

      if(!canned_response){
        console.error("canned_response is empty", canned_response);
        return true;
      }

      const exceed_length = key.length > this.keyMaxLength;
      if(exceed_length){
            console.error('key length exceeded', key, key.length, this.keyMaxLength);
            return true;
      }
      const exceed_canned_response = canned_response.length > this.templateMaxLength;
      if(exceed_canned_response){
            console.error('canned_response length exceeded', canned_response, canned_response.length, this.templateMaxLength);
            return true;
      }
      if(this.userIsSupervisor &&
          !this.filterDeps.some(filter => filter.active)){
          console.error('User is Supervisor. no department selected');
          return true;
      }
      return false;
    },
    isTemplate() {
      return this.template?.is_template;
    },
    toggleAll() {
      const allActive = this.filterDeps.find(filter => !filter.active);
      return allActive ? false : true;
    },
    totalTemplate() {
      const total =
        this.templateMaxLength - this.editTemplate.canned_response.length;
      return total >= 0 ? total : this.$t('editor.maxLength');
    },
    totalKey() {
      const total = this.keyMaxLength - this.editTemplate.key.length;
      return total >= 0 ? total : this.$t('editor.maxLength');
    }
  },
  mounted() {
    this.filterDeps = this.departments.map(({id, name}) => {
      return {
        id,
        name,
        active: false
      };
    });
    if (this.template) {
      const {canned_response, key, department_ids} = this.template;
      this.editTemplate = {
        canned_response,
        key,
        department_ids
      };
      this.filterDeps.forEach(dep => {
        if (this.editTemplate.department_ids.includes(dep.id)) {
          dep.active = true;
        }
      });
      this.title = this.$t('editor.title[1]');
      this.creating = false;
    } else {
      this.title = this.$t('editor.title[0]');
      this.creating = true;
    }
  },
  methods: {
    ...Vuex.mapActions([
      'closeEditor',
      'updateTemplate',
      'createTemplate',
      'notify'
    ]),
    updateDeps() {
      const selected = this.filterDeps
        .filter(dep => !!dep.active)
        .map(({id}) => id);
      this.editTemplate.department_ids = selected;
    },
    saveTemplate() {
      if (this.creating) {
        this.createTemplate({...this.editTemplate})
          .then(() => {
            this.notify({
              type: 'success',
              message: this.$tc('editor.notify.success', 0, {
                key: this.editTemplate.key
              })
            });
            this.closeEditor();
          })
          .catch(() => {
            this.notify({
              type: 'error',
              message: this.$tc('editor.notify.error', 1)
            });
          });
      } else {
        const {resource_id} = this.template;
        this.updateTemplate({...this.editTemplate, resource_id})
          .then(() => {
            this.notify({
              type: 'success',
              message: this.$tc('editor.notify.success', 2, {
                key: this.editTemplate.key
              })
            });
            this.closeEditor();
          })
          .catch(() => {
            this.notify({
              type: 'error',
              message: this.$tc('editor.notify.error', 2)
            });
          });
      }
    },
    onClose() {
      this.editTemplate = {canned_response: '', key: ''};
      this.closeEditor();
    },
    addAccount(type) {
      const keyword = type === 'name' ? '{{name}}' : '{{uname}}';
      this.editTemplate.canned_response = `${this.editTemplate.canned_response} ${keyword}`;
    },
    checkKey() {
      this.editTemplate.key = this.editTemplate.key
        .replace(/[^\w\s]/gi, '')
        .replace(/ /g, '_');
    }
  },
  template: `<SidebarEditor
    :title="title"
    :onSave="saveTemplate"
    :saveDisabled="disabledForm"
    :onCancel="onClose">
    <h5>{{ $t('editor.shortcut') }}</h5>
    <p v-if="!isTemplate">{{ $t('editor.shortcutDesc') }}</p>
    <InputText v-model="editTemplate.key" v-if="!isTemplate" @blur="checkKey" />
    <small v-if="!isTemplate">{{ totalKey }}</small>
    <strong v-else>{{ editTemplate.key }}</strong>
    <h5>{{ $t('editor.dep')}}</h5>
    <p>{{ $t('editor.depDesc') }}</p>
    <div class="selector-wrapper">
      <CustomSelector
        :filter-data="filterDeps"
        :tags="true"
        :changeData="updateDeps"
        :toggle-all="toggleAll"
      />
    </div>
    <h5>{{ $t('editor.templateText')}}</h5>
    <slot v-if="!isTemplate">
      <p>{{ $t('editor.templateTextDesc') }}</p>
      <button
        @click="addAccount('account')"
        class="btn ads-button--primary ads-button--primary-variant">
        {{ $t('editor.customerAccount') }}
      </button>
      <button
        @click="addAccount('name')"
        class="btn ads-button--primary ads-button--primary-variant">
        {{ $t('editor.accountName') }}
      </button>
    </slot>
    <slot v-if="!isTemplate">
    <textarea
      class="w-100 mt-1 ads-textarea"
      v-model="editTemplate.canned_response"></textarea>
      <small>{{ totalTemplate }}</small>
    </slot>
    <p v-else class="mt-2">{{ editTemplate.canned_response }}</p>

  </SidebarEditor>`
});
