'use strict';

class AdMessageDefinitions {

  translate: Function
  ENV: string
  messages: any

  constructor($filter: any, ENV: string) {
    this.translate = $filter('translate')
    this.ENV = ENV;
    this.messages = {}
    this.initStaticMessages()
  };

  getMethodMessage (
    code: number,
    method: string,
    description: string,
    sn: string,
    customCode: number,
    data: any
  ): string {

    this.initCustomMessages(
      code,
      method,
      description,
      sn,
      customCode,
      data
    )

    var finalMessage: string;

    if (angular.isDefined(this.messages[method])) {
      finalMessage = this.messages[method][code];
    }

    if (angular.isUndefined(finalMessage)) {
      var context = {method: method, code: code};
      finalMessage = this.translate('AD_MESSAGE_UNDEFINED', context);
      if (typeof Sentry  !== "undefined" && this.ENV !== '') {
        Sentry.captureException(new Error(finalMessage), {
          extra: {
            code: code,
            method: method,
            description: description,
            sn: sn,
            customCode: customCode,
            data: data
          }
        });
      }
    }
    return finalMessage;
  }

  initCustomMessages (
    code,
    method,
    description,
    sn,
    customCode,
    data
  ) {

    var isPublication = ['dm', 'reply', 'publish'].indexOf(method) >= 0;
    if (angular.isDefined(description) && isPublication) {
      if (code === 403) {
        if (description.indexOf('duplicate.') > -1) {
          this.messages[method][403] = this.translate(
            'AD_MESSAGE_PUBLICATION_DUPLICATED'
          );
        } else if (description.indexOf('who are not following you.') > -1) {
          this.messages[method][403] = this.translate(
            'AD_MESSAGE_PUBLICATION_NOT_FOLLOWING_YOU'
          );
        } else if (description.indexOf('over 140 characters') > -1) {
          this.messages[method][403] = this.translate(
            'AD_MESSAGE_PUBLICATION_OVER_140_CHARS'
          );
        } else if (description.indexOf('over 240 characters') > -1) {
          this.messages[method][403] = this.translate(
            'AD_MESSAGE_PUBLICATION_OVER_240_CHARS'
          );
        } else if (
          description.indexOf(
            "can't send direct this.messages to this user right now"
          ) > -1
        ) {
          this.messages[method][403] = this.translate(
            'AD_MESSAGE_PUBLICATION_TWITTER_CANT_SEND_DMS'
          );
        } else if (customCode == '368') {
          this.messages[method][403] = this.translate(
            'AD_MESSAGE_PUBLICATION_FACEBOOK_UNSAFE_LINK'
          );
        } else if (customCode == '230') {
          this.messages[method][403] = this.translate(
            'AD_MESSAGE_PUBLICATION_FACEBOOK_BLOCKED'
          );
        } else if (customCode == '190') {
          this.messages[method][403] = this.translate('AD_TOKEN_LOST');
        } else if (customCode == '32') {
          this.messages[method][403] = this.translate(
            'AD_MESSAGE_PUBLICATION_FACEBOOK_REQ_LIMIT_REACHED'
          );
        } else if (customCode == '2' || customCode == '1') {
          this.messages[method][403] = this.translate(
            'AD_MESSAGE_PUBLICATION_FACEBOOK_UNKNOWN'
          );
        } else if (description.indexOf('RAISED_STATUS_CODE') > -1) {
          this.messages[method][403] = this.translate('BANNED_KEYWORD_ALERT');
        } else if (description.indexOf('aliases you requested') > -1) {
          this.messages[method][403] = this.translate(
            'AD_MESSAGE_PUBLICATION_DELETED_MESSAGE'
          );
        } else {
          this.messages[method][403] = description;
        }
      } else if (code === 404) {
        if (description.indexOf('Account not found on publishing') > -1) {
          this.messages[method][404] = this.translate(
            'AD_MESSAGE_PUBLICATION_REMOVED_ACCOUNT'
          );
        } else if (method === 'reply') {
          this.messages[method][404] = this.translate(
            'AD_MESSAGE_PUBLICATION_DELETED_MESSAGE'
          );
        }
      } else if (code === 500) {
        this.messages[method][500] = description;
        if (description.indexOf('Twitter Error: 1') > -1) {
          this.messages[method][500] = this.translate(
            'AD_MESSAGE_PUBLICATION_TWITTER_CANT_SEND_DMS'
          );
        } else if (description.indexOf('Twitter Error: 3') > -1) {
          this.messages[method][500] = this.translate(
            'AD_MESSAGE_PUBLICATION_DELETED_OR_NOT_VISIBLE_MESSAGE'
          );
        } else if (description.indexOf('Twitter Error: 4') > -1) {
          this.messages[method][500] = this.translate(
            'AD_MESSAGE_PUBLICATION_OVER_280_CHARS'
          );
        } else if (sn == 'PostCenterMessage') {
          if (description.indexOf('Error queuing') > -1) {
            this.messages[method][500] = this.translate(
              'AD_MESSAGE_CHAT_UNABLE_TO_QUEUE'
            );
          } else if (description.indexOf('599') > -1) {
            this.messages[method][500] = this.translate('AD_MESSAGE_DM_ERROR');
          } else if (description.indexOf('404') > -1) {
            this.messages[method][500] = this.translate(
              'AD_MESSAGE_DM_ERROR_MISSING_CHAT'
            );
          }
        }
      }
    }

    var getConversation = ['get_conversation'].indexOf(method) >= 0;
    if (angular.isDefined(description) && getConversation) {
      if (code === 404) {
        if (description.indexOf('Merge') > -1) {
          this.messages[method][404] = this.translate(
            'AD_MESSAGE_GET_CONVERSATION_MERGE'
          );
        } else if (description.indexOf('no this.messages') > -1) {
          this.messages[method][404] = this.translate(
            'AD_MESSAGE_GET_CONVERSATION_NO_this.messages'
          );
        } else {
          this.messages[method][404] = description;
        }
      } else if (code === 500) {
        this.messages[method][500] = description;
      }
    }

    if (description && description.indexOf('IMAP connection error') > -1) {
      this.setOnError(
        'login_social_account',
        this.translate('AD_MESSAGE_CONNECT_IMAP_CONNECTION_ERROR')
      );
    } else if (
      description &&
      description.indexOf('IMAP login error') > -1
    ) {
      this.setOnError(
        'login_social_account',
        this.translate('AD_MESSAGE_CONNECT_IMAP_LOGIN_ERROR')
      );
    } else if (
      description &&
      description.indexOf('already exists in this establishment') > -1
    ) {
      this.setOnError(
        'login_social_account',
        this.translate('AD_MESSAGE_CONNECT_ALREADY_CONNECTED_ACCOUNT')
      );
    } else if (
      description &&
      description.indexOf('already exists in another establishment') > -1
    ) {
      this.setOnError(
        'login_social_account',
        this.translate(
          'AD_MESSAGE_CONNECT_ALREADY_CONNECTED_IN_ANOTHER_ESTABLISHMENT_ACCOUNT'
        )
      );
    }

    this.messages['whatsapp_account'] = {
      201: this.translate('AD_MESSAGE_WHATSAPP_ACCOUNT_201'),
      403: this.translate('AD_MESSAGE_WHATSAPP_ACCOUNT_403'),
      500: angular.isDefined(description)
        ? description
        : this.translate('AD_MESSAGE_WHATSAPP_ACCOUNT_500'),
      503: this.translate('AD_MESSAGE_WHATSAPP_ACCOUNT_500'),
      507: this.translate('AD_MESSAGE_WHATSAPP_ACCOUNT_507'),
      error: this.translate('AD_MESSAGE_WHATSAPP_ACCOUNT_ERROR'),
      400: this.translate('AD_MESSAGE_WHATSAPP_ACCOUNT_400')
    };

    this.messages['send_survey'] = {
      403: this.translate('SEND_SURVEY_403'),
      406: data && data.detail === 'Campaign does not allow survey' 
        ? this.translate('SEND_SURVEY_406_CAMPAIGN_NOT_ALLOW')
        : this.translate('SEND_SURVEY_406', {
          userName: data && data.username
        }),
      429: this.translate('SEND_SURVEY_429', {
        days: data && data.days,
        remainingDays: data && data['days_remaining'],
        pluralizationDays: data && data['days_remaining'] === 1 ? '' : 's'
      })
    };

    if (description && description.indexOf('IMAP connection error') > -1) {
      this.setOnError(
        'edit_social_account_config',
        this.translate('AD_MESSAGE_CONNECT_IMAP_CONNECTION_ERROR')
      );
    } else if (
      description &&
      description.indexOf('IMAP login error') > -1
    ) {
      this.setOnError(
        'edit_social_account_config',
        this.translate('AD_MESSAGE_CONNECT_IMAP_LOGIN_ERROR')
      );
    } else {
      this.setOnErrorAnd404(
        'edit_social_account_config',
        this.translate('AD_MESSAGE_EDIT_SOCIAL_ACCOUNT_CONFIG_ERROR')
      );
    }

    data && this.setOnOk('email_ticket_created', this.translate('AD_MESSAGE_EMAIL_TICKET_CREATED', {caseId: data.caseId}));
    this.setOnError(
      'error_creating_email_ticket',
      this.translate('AD_MESSAGE_EMAIL_TICKET_CREATED_ERROR')
    );

    data && this.setOnOk('whatsapp_template_sent', this.translate('AD_MESSAGE_WHATSAPP_TEMPLATE_SENT', {caseId: data.caseId}));
    this.setOnError(
      'error_sending_whatsapp_template',
      this.translate('AD_MESSAGE_WHATSAPP_TEMPLATE_SENT_ERROR')
    );

    this.messages['whatsapp_campaign_sent_successfully'] = {
      500: this.translate('WHATSAPP_CAMPAIGN_SENT_SUCCESSFULLY')
    };

    this.messages['error_getting_whatsapp_campaign'] = {
      500: this.translate('WHATSAPP_CAMPAIGN_ERROR_GETTING_CAMPAIGNS')
    };

    this.messages['error_getting_whatsapp_campaign_details'] = {
      500: this.translate('WHATSAPP_CAMPAIGN_ERROR_GETTING_CAMPAIGN_DETAILS')
    };

    this.messages['error_sending_whatsapp_campaign'] = {
      500: this.translate('WHATSAPP_CAMPAIGN_ERROR_SENDING_CAMPAIGN')
    };

    this.messages['error_creating_whatsapp_campaign'] = {
      500: this.translate('WHATSAPP_CAMPAIGN_ERROR_CREATING')
    };

    if (data && typeof data === 'object') {
      this.messages['media_upload'] = {
        403: this.translate('CASECONTROL_MEDIA_BLOCKED', { extension: data.extension })
      };

      this.messages['whatsapp_campaign_created_successfully'] = {
        500: this.translate('WHATSAPP_CAMPAIGN_CREATED_SUCCESSFULLY', data)
      };
    }
  };

  initStaticMessages () {

    this.messages['publish'] = {
      201: this.translate('AD_MESSAGE_PUBLISH_OK'),
      403: this.translate('AD_MESSAGE_PUBLISH_OK'),
      0: this.translate('AD_MESSAGE_PUBLISH_ERROR'),
      500: this.translate('AD_MESSAGE_PUBLISH_ERROR'),
      503: this.translate('AD_MESSAGE_PUBLISH_ERROR')
    };

    this.messages['microphone_access_error'] = {
      403: this.translate('AD_MESSAGE_MICROPHONE_ACCESS_ERROR'),
    };

    this.messages['dm'] = {
      201: this.translate('AD_MESSAGE_DM_OK'),
      403: this.translate('AD_MESSAGE_DM_FORBIDDEN'),
      409: this.translate('AD_MESSAGE_OUT_OF_SCHEDULE'),
      423: this.translate('AD_MESSAGE_OPTIN_REQUIRED'),
      500: this.translate('AD_MESSAGE_DM_ERROR'),
      501: this.translate('AD_MESSAGE_DM_ERROR'),
      502: this.translate('AD_MESSAGE_DM_ERROR'),
      503: this.translate('AD_MESSAGE_DM_ERROR'),
      0: this.translate('AD_MESSAGE_DM_ERROR'),
      '-1': this.translate('AD_MESSAGE_DM_ERROR')
    };

    this.messages['reply'] = {
      201: this.translate('AD_MESSAGE_REPLY_OK'),
      500: this.translate('AD_MESSAGE_REPLY_ERROR'),
      403: this.translate('AD_MESSAGE_REPLY_FORBIDDEN'),
      0: this.translate('AD_MESSAGE_REPLY_ERROR'),
      501: this.translate('AD_MESSAGE_REPLY_ERROR'),
      502: this.translate('AD_MESSAGE_REPLY_ERROR'),
      503: this.translate('AD_MESSAGE_REPLY_ERROR'),
      '-1': this.translate('AD_MESSAGE_REPLY_ERROR')
    };

    this.messages['fb_lib_not_loaded'] = {
      500: this.translate('AD_FB_NOT_LOADED_ERROR')
    };

    this.messages['get_conversation'] = {
      201: this.translate('AD_MESSAGE_GET_CONVERSATION_RETRIEVED'),
      401: this.translate('AD_MESSAGE_GET_CONVERSATION_NOT_FOUND'),
      404: this.translate('AD_MESSAGE_GET_CONVERSATION_NOT_FOUND'),
      0: this.translate('AD_MESSAGE_GET_CONVERSATION_NOT_FOUND'),
      '-1': this.translate('AD_MESSAGE_GET_CONVERSATION_SERVER_ERROR'),
      500: this.translate('AD_MESSAGE_GET_CONVERSATION_SERVER_ERROR')
    };

    this.setOnError(
      'no_manual_reopen_on_ticket',
      this.translate('AD_NO_MANUAL_REOPEN_ON_TICKET')
    );

    // Crud COS
    this.setOnOk('cos_updated', this.translate('COS_UPDATED_CORRECT'));
    this.setOnError('cos_updated', this.translate('COS_UPDATED_INCORRECT'));

    this.setOnOk('save_crm_properties', this.translate('CRM_SAVE_PROPERITES_OK'))
    this.setOnError('save_crm_properties', this.translate('CRM_SAVE_PROPERITES_ERROR'));

    // Crud this.messages on Notification Agenda Users
    this.setOnOk(
      'added_notification_agenda_user',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_USER_ADDED')
    );
    this.setOnErrorAnd404(
      'added_notification_agenda_user',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_USER_ADDED_ERROR')
    );
    this.setOnOk(
      'edit_notification_agenda_user',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_USER_EDITED')
    );
    this.setOnErrorAnd404(
      'edit_notification_agenda_user',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_USER_EDITED_ERROR')
    );
    this.setOnOk(
      'deleted_notification_agenda_user',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_USER_DELETED')
    );
    this.setOnErrorAnd404(
      'deleted_notification_agenda_user',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_USER_DELETED_ERROR')
    );

    // Crud this.messages on Notification Agenda Groups
    this.setOnOk(
      'added_notification_agenda_group',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_GROUP_ADDED')
    );
    this.setOnErrorAnd404(
      'added_notification_agenda_group',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_GROUP_ADDED_ERROR')
    );
    this.setOnOk(
      'edit_notification_agenda_group',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_GROUP_EDITED')
    );
    this.setOnErrorAnd404(
      'edit_notification_agenda_group',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_GROUP_EDITED_ERROR')
    );
    this.setOnOk(
      'deleted_notification_agenda_group',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_GROUP_DELETED')
    );
    this.setOnErrorAnd404(
      'deleted_notification_agenda_group',
      this.translate('AD_MESSAGE_NOTIFICATION_AGENDA_GROUP_DELETED_ERROR')
    );

    this.setOnOk('enable_integration', this.translate('ADMIN_ENABLED_INTEGRATION'));
    this.setOnErrorAnd404(
      'enable_integration',
      this.translate('ADMIN_ENABLED_INTEGRATION_ERROR')
    );

    this.setOnOk('disable_integration', this.translate('ADMIN_DISABLED_INTEGRATION'));
    this.setOnErrorAnd404(
      'disable_integration',
      this.translate('ADMIN_DISABLED_INTEGRATION_ERROR')
    );

    this.setOnOk(
      'save_twitter_options',
      this.translate('AD_MESSAGE_SAVE_TWITTER_OPTIONS_OK')
    );
    this.setOnErrorAnd404(
      'save_twitter_options',
      this.translate('AD_MESSAGE_SAVE_TWITTER_OPTIONS_ERROR')
    );

    this.setOnOk(
      'new_column_from_user',
      this.translate('AD_MESSAGE_NEW_COLUMN_FROM_USER_OK')
    );
    this.setOnErrorAnd404(
      'new_column_from_user',
      this.translate('AD_MESSAGE_NEW_COLUMN_FROM_USER_ERROR')
    );

    this.setOnOk('login', this.translate('AD_MESSAGE_LOGIN_OK'));
    this.messages['login'] = {
      401: this.translate('AD_MESSAGE_LOGIN_401'),
      403: this.translate('AD_MESSAGE_LOGIN_403'),
      500: this.translate('AD_MESSAGE_LOGIN_500'),
      0: this.translate('AD_MESSAGE_LOGIN_0')
    };
    this.messages['login'][-1] = this.translate('AD_MESSAGE_LOGIN_0');

    // why Ok is not set for this method ???
    this.setOnErrorAnd404(
      'get_profile',
      this.translate('AD_MESSAGE_GET_PROFILE_ERROR')
    );

    this.messages['post_column'] = {
      201: this.translate('AD_MESSAGE_POST_COLUMN_201'),
      403: this.translate('AD_MESSAGE_POST_COLUMN_403'),
      500: this.translate('AD_MESSAGE_POST_COLUMN_500'),
      error: this.translate('AD_MESSAGE_POST_COLUMN_ERROR'),
      0: this.translate('AD_MESSAGE_POST_COLUMN_ERROR')
    };

    this.setOnOk('export_column', this.translate('AD_MESSAGE_EXPORT_COLUMN_201'));
    this.setOnErrorAnd404(
      'export_column',
      this.translate('AD_MESSAGE_EXPORT_COLUMN_ERROR')
    );

    this.setOnOk('get_columns', this.translate('AD_MESSAGE_GET_COLUMNS_OK'));
    this.setOnErrorAnd404(
      'get_columns',
      this.translate('AD_MESSAGE_GET_COLUMNS_ERROR')
    );

    this.messages['edit_column'] = {
      403: this.translate('AD_MESSAGE_EDIT_COLUMN_403'),
      404: this.translate('AD_MESSAGE_EDIT_COLUMN_404'),
      500: this.translate('AD_MESSAGE_EDIT_COLUMN_500')
    };

    this.setOnOk('delete_column', this.translate('AD_MESSAGE_DELETE_COLUMN_OK'));
    this.setOnErrorAnd404(
      'delete_column',
      this.translate('AD_MESSAGE_DELETE_COLUMN_ERROR')
    );

    this.setOnOk('assign_cm', this.translate('AD_MESSAGE_ASSIGN_CM_OK'));
    this.setOnErrorAnd404('assign_cm', this.translate('AD_MESSAGE_ASSIGN_CM_ERROR'));

    this.setOnOk('comment_case', this.translate('AD_MESSAGE_COMMENT_CASE_OK'));
    this.setOnErrorAnd404(
      'comment_case',
      this.translate('AD_MESSAGE_COMMENT_CASE_ERROR')
    );

    this.setOnOk('set_important', this.translate('AD_MESSAGE_SET_IMPORTANT_OK'));
    this.setOnErrorAnd404(
      'set_important',
      this.translate('AD_MESSAGE_SET_IMPORTANT_ERROR')
    );

    this.setOnOk('set_unimportant', this.translate('AD_MESSAGE_SET_UNIMPORTANT_OK'));
    this.setOnErrorAnd404(
      'set_unimportant',
      this.translate('AD_MESSAGE_SET_UNIMPORTANT_ERROR')
    );

    this.messages['add_custom_data_message'] = {
      201: this.translate('AD_MESSAGE_ADD_CUSTOM_DATA_MESSAGE_201'),
      500: this.translate('AD_MESSAGE_ADD_CUSTOM_DATA_MESSAGE_500'),
      error: this.translate('AD_MESSAGE_ADD_CUSTOM_DATA_MESSAGE_ERROR')
    };
    this.messages['get_establishment'] = {
      500: this.translate('AD_MESSAGE_GET_ESTABLISHMENT_ERROR'),
      error: this.translate('AD_MESSAGE_GET_ESTABLISHMENT_ERROR')
    };
    this.messages['edit_establishment'] = {
      201: this.translate('AD_MESSAGE_EDIT_ESTABLISHMENT_OK')
    };
    this.messages['establishmentKeywords'] = {
      201: this.translate('AD_MESSAGE_ESTABLISHMENT_KEYWORDS_OK')
    };

    this.messages['login_social_account'] = {
      201: this.translate('AD_MESSAGE_LOGIN_SOCIAL_ACCOUNT_201'),
      403: this.translate('AD_MESSAGE_LOGIN_SOCIAL_ACCOUNT_403'),
      500: this.translate('AD_MESSAGE_LOGIN_SOCIAL_ACCOUNT_500'),
      error: this.translate('AD_MESSAGE_LOGIN_SOCIAL_ACCOUNT_ERROR')
    };

    this.messages['update_avatar'] = {
      200: this.translate('AD_MESSAGE_UPDATE_AVATAR_200'),
      500: this.translate('AD_MESSAGE_UPDATE_AVATAR_500'),
      404: this.translate('AD_MESSAGE_UPDATE_AVATAR_NOT_FOUND'),
      not_square: this.translate('SOCIALACCOUNTS_AVATAR_NOT_SQUARE'),
      too_big: this.translate('SOCIALACCOUNTS_AVATAR_TOO_BIG'),
      too_small: this.translate('SOCIALACCOUNTS_AVATAR_TOO_SMALL'),
      error: this.translate('AD_MESSAGE_UPDATE_AVATAR_ERROR')
    };

    this.messages['get_social_accounts'] = {
      500: this.translate('AD_MESSAGE_GET_SOCIAL_ACCOUNTS_ERROR'),
      error: this.translate('AD_MESSAGE_GET_SOCIAL_ACCOUNTS_ERROR')
    };
    this.messages['get_departments'] = {
      500: this.translate('AD_MESSAGE_GET_DEPARTMENTS_ERROR'),
      error: this.translate('AD_MESSAGE_GET_DEPARTMENTS_ERROR')
    };
    this.messages['add_department'] = {
      201: this.translate('AD_MESSAGE_ADD_DEPARTMENT_201'),
      500: this.translate('AD_MESSAGE_ADD_DEPARTMENT_ERROR'),
      error: this.translate('AD_MESSAGE_ADD_DEPARTMENT_ERROR')
    };
    this.messages['edit_department'] = {
      201: this.translate('AD_MESSAGE_EDIT_DEPARTMENT_201'),
      404: this.translate('AD_MESSAGE_EDIT_DEPARTMENT_404'),
      500: this.translate('AD_MESSAGE_EDIT_DEPARTMENT_ERROR')
    };
    this.messages['delete_departments'] = {
      201: this.translate('AD_MESSAGE_DELETE_DEPARTMENTS_201'),
      500: this.translate('AD_MESSAGE_DELETE_DEPARTMENTS_ERROR'),
      error: this.translate('AD_MESSAGE_DELETE_DEPARTMENTS_ERROR')
    };
    this.messages['edit_user'] = {
      201: this.translate('AD_MESSAGE_EDIT_USER_201')
    };
    this.messages['add_canned_response'] = {
      201: this.translate('AD_MESSAGE_ADD_CANNED_RESPONSE_201')
    };
    this.messages['edit_canned_response'] = {
      201: this.translate('AD_MESSAGE_EDIT_CANNED_RESPONSE_201'),
      403: this.translate('AD_MESSAGE_EDIT_CANNED_RESPONSE_403')
    };
    this.messages['delete_canned_response'] = {
      201: this.translate('AD_MESSAGE_DELETE_CANNED_RESPONSE_201'),
      404: this.translate('AD_MESSAGE_DELETE_CANNED_RESPONSE_404')
    };
    this.messages['delete_user'] = {
      201: this.translate('AD_MESSAGE_DELETE_USER_201')
    };
    this.messages['deactivate_user'] = {
      201: this.translate('AD_MESSAGE_DEACTIVATE_USER_201')
    };
    this.messages['activate_user'] = {
      201: this.translate('AD_MESSAGE_ACTIVATE_USER_201')
    };
    this.messages['get_users'] = {
      500: this.translate('AD_MESSAGE_GET_USERS_ERROR'),
      error: this.translate('AD_MESSAGE_GET_USERS_ERROR')
    };
    this.messages['add_user'] = {
      201: this.translate('AD_MESSAGE_ADD_USER_201')
    };
    this.messages['update_assignments'] = {
      202: this.translate('AD_MESSAGE_UPDATE_ASSIGNMENTS_202'),
      new_design_title: this.translate('ASSIGNMENTS_NEW_DESIGN.WELCOME_TITLE'),
      new_design_body: this.translate('ASSIGNMENTS_NEW_DESIGN.WELCOME_BODY')
    };
    this.messages['add_edit_custom_data'] = {
      202: this.translate('AD_MESSAGE_ADD_EDIT_CUSTOM_DATA_202'),
      invalid: this.translate('AD_MESSAGE_ADD_EDIT_CUSTOM_DATA_INVALID'),
      400: this.translate(
        'AD_MESSAGE_ADD_EDIT_CUSTOM_DATA_DUPLICATE_INTEGRATION_KEY'
      )
    };
    this.messages['delete_custom_data'] = {
      200: this.translate('AD_MESSAGE_DELETE_CUSTOM_DATA_200')
    };
    this.messages['add_cos'] = {
      201: this.translate('COS_SAVE_SUCCESS'),
      error: this.translate('COS_SAVE_ERROR')
    };
    this.messages['case_copy_to_clipboard'] = {
      200: this.translate('CASECONTROL_COPY_ID_SUCCESS')
    };
    this.messages['link_copied_to_clipboard'] = {
      200: this.translate('CHECKOUT_LINK_COPY_SUCCESS')
    };
    this.messages['order_copied_to_clipboard'] = {
      200: this.translate('ORDER_COPY_ID_SUCCESS')
    };
    this.messages['token_copied_to_clipboard'] = {
      200: this.translate('TOKEN_COPY_SUCCESS')
    };
    this.messages['orders_obtain_error'] = {
      500: this.translate('ORDERS_OBTAIN_ERROR')
    };
    this.messages['checkouts_obtain_error'] = {
      500: this.translate('CHECKOUTS_OBTAIN_ERROR')
    };

    this.messages['create_case_from_message'] = {
      '-1': this.translate('AD_MESSAGE_CREATE_CASE_FROM_MESSAGE_OFFLINE'),
      0: this.translate('AD_MESSAGE_CREATE_CASE_FROM_MESSAGE_OFFLINE'),
      200: this.translate('AD_MESSAGE_CREATE_CASE_FROM_MESSAGE_OK'),
      201: this.translate('AD_MESSAGE_CREATE_CASE_FROM_MESSAGE_OK'),
      202: this.translate('AD_MESSAGE_CREATE_CASE_FROM_MESSAGE_OK'),
      400: this.translate('AD_MESSAGE_DETAILED_400'),
      403: this.translate('AD_MESSAGE_CREATE_CASE_FROM_MESSAGE_FORBIDDEN'),
      404: this.translate('AD_MESSAGE_CREATE_CASE_FROM_MESSAGE_NOT_FOUND'),
      500: this.translate('AD_MESSAGE_CREATE_CASE_FROM_MESSAGE_ERROR')
    };

    this.messages['contact_agenda'] = {
      200: this.translate('CONTACT_AGENDA.REQUESTS.OK'),
      201: this.translate('CONTACT_AGENDA.REQUESTS.MESSAGE_SENT'),
      403: this.translate('CONTACT_AGENDA.REQUESTS.MESSAGE_NOT_SENT'),
      500: this.translate('CONTACT_AGENDA.REQUESTS.ERROR'),
      contact_updated: this.translate('CONTACT_AGENDA.REQUESTS.CONTACT_UPDATED'),
      no_tickets: this.translate('CONTACT_AGENDA.REQUESTS.NO_TICKETS'),
      success_copy_id: this.translate('CASECONTROL_COPY_ID_SUCCESS'),
      note_updated: this.translate('CONTACT_AGENDA.REQUESTS.NOTE_UPDATED'),
      note_deleted: this.translate('CONTACT_AGENDA.REQUESTS.NOTE_DELETED'),
      twitter_error_1: this.translate('TWITTER_ERROR_1'),
      twitter_error_2: this.translate('TWITTER_ERROR_2'),
      twitter_error_3: this.translate('TWITTER_ERROR_3'),
      twitter_error_4: this.translate('TWITTER_ERROR_4'),
      twitter_error_5: this.translate('TWITTER_ERROR_5')
    };

    this.setOnOk(
      'edit_social_account_config',
      this.translate('AD_MESSAGE_EDIT_SOCIAL_ACCOUNT_CONFIG_OK')
    );

    this.setOnOk(
      'disconnect_social_account',
      this.translate('AD_MESSAGE_DISCONNECT_SOCIAL_ACCOUNT_OK')
    );
    this.setOnErrorAnd404(
      'disconnect_social_account',
      this.translate('AD_MESSAGE_DISCONNECT_SOCIAL_ACCOUNT_ERROR')
    );
    this.messages['disconnect_social_account'][502] = this.translate(
      'AD_MESSAGE_DETAILED_502'
    );

    this.setOnOk(
      'add_custom_data_user',
      this.translate('AD_MESSAGE_ADD_CUSTOM_DATA_USER_OK')
    );
    this.setOnErrorAnd404(
      'add_custom_data_user',
      this.translate('AD_MESSAGE_ADD_CUSTOM_DATA_USER_ERROR')
    );

    this.setOnOk(
      'add_custom_data_case',
      this.translate('AD_MESSAGE_ADD_CUSTOM_DATA_CASE_OK')
    );
    this.setOnErrorAnd404(
      'add_custom_data_case',
      this.translate('AD_MESSAGE_ADD_CUSTOM_DATA_CASE_ERROR')
    );

    this.setOnOk(
      'set_sentiment_message',
      this.translate('AD_MESSAGE_SET_SENTIMENT_MESSAGE_OK')
    );
    this.setOnErrorAnd404(
      'set_sentiment_message',
      this.translate('AD_MESSAGE_SET_SENTIMENT_MESSAGE_ERROR')
    );

    this.setOnOk(
      'loading_manage_account',
      this.translate('AD_MESSAGE_LOADING_MANAGE_ACCOUNT_OK')
    );
    this.setOnErrorAnd404(
      'loading_manage_account',
      this.translate('AD_MESSAGE_LOADING_MANAGE_ACCOUNT_ERROR')
    );

    this.setOnOk(
      'loading_post_center',
      this.translate('AD_MESSAGE_LOADING_POST_CENTER_OK')
    );
    this.setOnErrorAnd404(
      'loading_post_center',
      this.translate('AD_MESSAGE_LOADING_POST_CENTER_ERROR')
    );
    this.messages['loading_post_center'][401] = this.translate(
      'AD_MESSAGE_LOADING_401'
    );

    this.messages['highlightQuotedMessageError'] = {
      400: this.translate('AD_QUOTED_MESSAGE_NOT_FOUND')
    };

    this.messages['user_was_disabled'] = {
      401: this.translate('AD_MESSAGE_DISABLED_USER_401')
    };
    this.messages['other_user_steal_session'] = {
      401: this.translate('AD_MESSAGE_STEAL_SESSION_401')
    };
    this.messages['retry_request_error'] = {
      500: this.translate('AD_MESSAGE_RETRY_ERROR_500')
    };

    this.setOnOk(
      'loading_establishment',
      this.translate('AD_MESSAGE_LOADING_ESTABLISHMENT_OK')
    );
    this.setOnErrorAnd404(
      'loading_establishment',
      this.translate('AD_MESSAGE_LOADING_ESTABLISHMENT_ERROR')
    );

    this.setOnOk('close_case', this.translate('AD_MESSAGE_CLOSE_CASE_OK'));
    this.setOnErrorAnd404(
      'close_case',
      this.translate('AD_MESSAGE_CLOSE_CASE_ERROR')
    );
    this.messages['close_case'][400] = this.translate(
      'AD_MESSAGE_CLOSE_CASE_INVALID_CUSTOM_DATA'
    );
    this.setOnOk('reopen_case', this.translate('AD_MESSAGE_REOPEN_CASE_OK'));
    this.setOnErrorAnd404(
      'reopen_case',
      this.translate('AD_MESSAGE_REOPEN_CASE_ERROR')
    );
    this.setOnErrorAnd404(
      'update_ticket_workflow_state',
      this.translate('AD_MESSAGE_REOPEN_CASE_ERROR')
    );
    this.messages['update_ticket_workflow_state'][400] = this.translate(
      'AD_MESSAGE_CLOSE_CASE_INVALID_CUSTOM_DATA'
    );

    this.setOnOk('merge_case', this.translate('AD_MESSAGE_MERGE_CASE_OK'));
    this.setOnErrorAnd404(
      'merge_case',
      this.translate('AD_MESSAGE_MERGE_CASE_ERROR')
    );

    this.setOnOk(
      'get_previous_case',
      this.translate('AD_MESSAGE_GET_PREVIOUS_CASE_OK')
    );
    this.setOnErrorAnd404(
      'get_previous_case',
      this.translate('AD_MESSAGE_GET_PREVIOUS_CASE_ERROR')
    );

    this.setOnOk('escalate_case', this.translate('AD_MESSAGE_CASE_ESCALATION_OK'));
    this.setOnErrorAnd404(
      'escalate_case',
      this.translate('AD_MESSAGE_CASE_ESCALATION_ERROR')
    );

    this.setOnOk('add_domain', this.translate('AD_MESSAGE_ADD_DOMAIN_OK'));
    this.setOnErrorAnd404(
      'add_domain',
      this.translate('AD_MESSAGE_ADD_DOMAIN_ERROR')
    );
    this.messages['add_domain'][403] = this.translate('AD_MESSAGE_ADD_DOMAIN_403');

    this.setOnOk(
      'disconnect_domain',
      this.translate('AD_MESSAGE_DISCONNECT_DOMAIN_OK')
    );
    this.setOnErrorAnd404(
      'disconnect_domain',
      this.translate('AD_MESSAGE_DISCONNECT_DOMAIN_ERROR')
    );

    this.setOnError(
      'forgotten_password',
      this.translate('AD_MESSAGE_FORGOTTEN_PASSWORD_ERROR')
    );
    this.setOnError(
      'get_newer_this.messages_from_column',
      this.translate('AD_MESSAGE_GET_NEWER_this.messages_FROM_COLUMN_ERROR')
    );

    this.setOnOk('share_message', this.translate('AD_MESSAGE_SHARE_MESSAGE_OK'));
    this.setOnErrorAnd404Detailed(
      'share_message',
      this.translate('AD_MESSAGE_SHARE_MESSAGE_ERROR'),
      this.translate('AD_MESSAGE_MESSAGE_RESOURCE')
    );
    this.messages['share_message'][400] = this.translate(
      'AD_MESSAGE_SHARE_MESSAGE_400'
    );

    this.setOnOk('like_message', this.translate('AD_MESSAGE_LIKE_MESSAGE_OK'));
    this.setOnErrorAnd404Detailed(
      'like_message',
      this.translate('AD_MESSAGE_LIKE_MESSAGE_ERROR'),
      this.translate('AD_MESSAGE_MESSAGE_RESOURCE')
    );
    this.messages['like_message'][400] = this.translate(
      'AD_MESSAGE_LIKE_MESSAGE_400'
    );

    this.setOnOk('delete_message', this.translate('AD_MESSAGE_DELETE_MESSAGE_OK'));
    this.setOnErrorAnd404Detailed(
      'delete_message',
      this.translate('AD_MESSAGE_DELETE_MESSAGE_ERROR'),
      this.translate('AD_MESSAGE_MESSAGE_RESOURCE')
    );

    this.setOnOk('hide_message', this.translate('AD_MESSAGE_HIDE_MESSAGE_OK'));
    this.setOnErrorAnd404Detailed(
      'hide_message',
      this.translate('AD_MESSAGE_HIDE_MESSAGE_ERROR'),
      this.translate('AD_MESSAGE_MESSAGE_RESOURCE')
    );

    this.setOnOk('unhide_message', this.translate('AD_MESSAGE_UNHIDE_MESSAGE_OK'));
    this.setOnErrorAnd404Detailed(
      'unhide_message',
      this.translate('AD_MESSAGE_UNHIDE_MESSAGE_ERROR'),
      this.translate('AD_MESSAGE_MESSAGE_RESOURCE')
    );

    this.setOnOk(
      'edit_notifications',
      this.translate('AD_MESSAGE_EDIT_NOTIFICATIONS_OK')
    );
    this.setOnErrorAnd404Detailed(
      'edit_notifications',
      this.translate('AD_MESSAGE_EDIT_NOTIFICATIONS_ERROR'),
      this.translate('AD_MESSAGE_NOTIFICATION_RESOURCE')
    );

    this.setOnOk(
      'edit_businesstime',
      this.translate('AD_MESSAGE_EDIT_BUSINESSTIME_OK')
    );
    this.setOnErrorAnd404Detailed(
      'edit_businesstime',
      this.translate('AD_MESSAGE_EDIT_BUSINESSTIME_ERROR'),
      this.translate('AD_MESSAGE_BUSINESSTIME_RESOURCE')
    );

    this.setOnOk(
      'toggle_influencer',
      this.translate('AD_MESSAGE_TOGGLE_INFLUENCER_OK')
    );
    this.setOnError(
      'toggle_influencer',
      this.translate('AD_MESSAGE_TOGGLE_INFLUENCER_ERROR')
    );

    this.messages['edit_user_account_assignment'] = {
      200: this.translate('AD_MESSAGE_EDIT_USER_ACCOUNT_ASIGNMENT_OK'),
      201: this.translate('AD_MESSAGE_EDIT_USER_ACCOUNT_ASIGNMENT_OK'),
      400: this.translate('AD_MESSAGE_EDIT_USER_ACCOUNT_ASIGNMENT_ERROR'),
      404: this.translate('AD_MESSAGE_EDIT_USER_ACCOUNT_ASIGNMENT_ERROR'),
      403: this.translate('AD_MESSAGE_EDIT_USER_ACCOUNT_ASIGNMENT_ERROR'),
      500: this.translate('AD_MESSAGE_EDIT_USER_ACCOUNT_ASIGNMENT_ERROR')
    };

    this.setOnOk(
      'edit_admin_settings',
      this.translate('AD_MESSAGE_EDIT_ADMIN_SETTINGS_OK')
    );
    this.setOnErrorAnd404(
      'edit_admin_settings',
      this.translate('AD_MESSAGE_EDIT_ADMIN_SETTINGS_ERROR')
    );

    this.messages['file_upload'] = {
      200: this.translate('AD_MESSAGE_FILE_UPLOAD_OK'),
      500: this.translate('AD_MESSAGE_FILE_UPLOAD_ERROR')
    };

    this.setOnOk(
      'send_email_validation',
      this.translate('AD_MESSAGE_SEND_EMAIL_VALIDATION_OK')
    );
    this.setOnError(
      'send_email_validation',
      this.translate('AD_MESSAGE_SEND_EMAIL_VALIDATION_ERROR')
    );

    this.setOnOk(
      'add_banned_keyword',
      this.translate('BANNED_KEYWORDS_EDIT_ADD_SUCCESS')
    );
    this.setOnOk(
      'edit_banned_keyword',
      this.translate('BANNED_KEYWORDS_EDIT_EDIT_SUCCESS')
    );
    this.setOnOk(
      'duplicate_banned_keyword',
      this.translate('BANNED_KEYWORDS_EDIT_EDIT_DUPLICATED')
    );
    this.setOnOk(
      'delete_banned_keyword_response',
      this.translate('BANNED_KEYWORDS_DELETE_SUCCESS')
    );

    this.messages['email_verification'] = {
      201: this.translate('SIGNUP_EMAIL_VERIFIED_SUCCESSFULLY'),
      400: this.translate('SIGNUP_EMAIL_VERIFICATION_FAILURE')
    };

    this.messages['resend_verification_email'] = {
      202: this.translate('GENERAL_EMAIL_RESEND_SUCCESS'),
      400: this.translate('GENERAL_EMAIL_RESEND_FAILURE')
    };

    this.messages['analytics'] = {
      500: this.translate('ANALYTICS_ERROR_LOADING_DATA')
    };

    this.messages['external_signup'] = {
      401: this.translate('INVALID_EXTERNAL_SIGNUP_TOKEN')
    };

    this.messages['channel_config_saved'] = {
      200: this.translate('AD_MESSAGE_EDIT_SOCIAL_ACCOUNT_CONFIG_OK')
    };
    this.messages['load_channel_config'] = {
      400: this.translate('AD_MESSAGE_GET_SOCIAL_ACCOUNT_ERROR')
    };

    this.messages['widget_code_copied'] = {
      200: this.translate('WIDGET_CODE_COPY_SUCCESS')
    };
  };

  setOnOk(key: string, msg: string) {
    if (!(key in this.messages)) {
      this.messages[key] = {};
    }
    this.messages[key][200] = msg;
    this.messages[key][201] = msg;
    this.messages[key][202] = msg;
  };

  setOnError(key: string, msg: string) {
    if (!(key in this.messages)) {
      this.messages[key] = {};
    }
    this.messages[key][-1] = msg;
    this.messages[key][0] = msg;
    this.messages[key][400] = msg;
    this.messages[key][500] = msg;
    this.messages[key]['error'] = msg;
  };

  setOnErrorAnd404(key: string, msg: string) {
    this.setOnError(key, msg);
    this.messages[key][404] = msg;
    this.messages[key][403] = msg;
  };

  setOnErrorAnd404Detailed(key: string, msg: string, resourceName: string) {
    resourceName = resourceName || 'Resource';
    this.setOnErrorAnd404(key, msg);
    this.messages[key][400] += ' ' + this.translate('AD_MESSAGE_DETAILED_400');
    this.messages[key][403] += ' ' + this.translate('AD_MESSAGE_DETAILED_403');
    this.messages[key][404] +=
      ' ' +
      this.translate('AD_MESSAGE_DETAILED_404', {resourceName: resourceName});
    this.messages[key][500] += ' ' + this.translate('AD_MESSAGE_DETAILED_500');
    this.messages[key]['error'] += ' ' + this.translate('AD_MESSAGE_DETAILED_500');
    this.messages[key][-1] += ' ' + this.translate('AD_MESSAGE_DETAILED_500');
    this.messages[key][0] += ' ' + this.translate('AD_MESSAGE_DETAILED_500');
  };
};

angular
  .module('postCenterWebClientApp')
  .service('AdMessageDefinitions', AdMessageDefinitions);
