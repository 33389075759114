'use strict';

var app = angular.module('postCenterWebClientApp');
app.controller('LinkableCrmCtrl', LinkableCrmCtrl);

LinkableCrmCtrl.$inject = [
  '$scope',
  'UserService',
  '$filter',
  'AdNotification',
  'CrmService',
  'crmErrorMessage',
  '$uibModalInstance',
  'linkableDataCrm'
];

function LinkableCrmCtrl(
  $scope,
  UserService,
  $filter,
  AdNotification,
  CrmService,
  crmErrorMessage,
  $uibModalInstance,
  linkableDataCrm
) {
  const {possibleProperties, crmName} = linkableDataCrm;

  console.log(crmName);

  if (crmName === 'salesforce') {
    $scope.crmNameCapitalize = 'Salesforce';
  } else if (crmName === 'hubspot') {
    $scope.crmNameCapitalize = 'HubSpot';
  }
  $scope.crmName = crmName;
  $scope.possibleProperties = possibleProperties;
  $scope.loading = true;
  $scope.linkingToCrm = false;
  $scope.creatingEntity = false;
  $scope.availableCrm = [];
  $scope.locale = 'es';

  UserService.getProfile().then(user => {
    $scope.locale = user.profile.language
  })

  const searchCrmList = () => {
    const {
      metadata_key,
      metadata_value,
      entityType: entity,
      crmName
    } = linkableDataCrm;
    CrmService.searchMetadataCrm(linkableDataCrm, {
      metadata_key,
      metadata_value
    })
      .then(({results}) => {
        $scope.availableCrm = results;
      })
      .catch(error => {
        errorCrmManager({error, entity, crmName});
        $uibModalInstance.close();
      })
      .finally(() => {
        $scope.loading = false;
        $scope.$applyAsync();
      });
  };

  searchCrmList();

  $scope.linkUserCrm = (event, crm) => {
    event.stopPropagation();
    crm.linking = true;
    $scope.linkingToCrm = true;
    const {entityType: entity, crmName, resource_id} = linkableDataCrm;

    const data = {
      pc_id: resource_id,
      crm_id: crm.id
    };

    CrmService.linkMetadataCrm(linkableDataCrm, data)
      .then(() => {
        displaySuccessMessageCrmLinked(entity);
        $uibModalInstance.close(true);
      })
      .catch(error => {
        errorCrmManager({error, entity, crmName});
      })
      .finally(() => {
        $scope.linking = false;
        $scope.linkingToCrm = false;
      });
  };

  $scope.getProperties = () => {
    let properties = {}
    for (let i = 0; i < $scope.possibleProperties.length; i++) {
      const property = $scope.possibleProperties[i];
      if (property.enabled) {
        properties[property.metadata_key] = property.metadata_value;
      }
    };
    return properties;
  };

  $scope.createCrmEntity = (event) => {
    event.stopPropagation();
    $scope.creatingEntity = true;
    const {entityType: entity, crmName, resource_id} = linkableDataCrm;

    const properties = $scope.getProperties();

    const data = {
      properties: properties,
      adereso_id: resource_id
    }
    CrmService.createEntity(linkableDataCrm, data)
      .then(() => {
        displaySuccessMessageCrmCreated(entity);
        $uibModalInstance.close(true);
      })
      .catch(error => {
        errorCrmManager({error, entity, crmName});
      })
      .finally(() => {
        $scope.creatingEntity = false;
        $scope.$applyAsync();
      });
  };

  $scope.getHeaderCollapse = (crm = {}) => {
    const extra = {id: null, open: null};
    const crmLink = Object.entries({...crm, ...extra});
    const description = crmLink
      .slice(0, -2)
      .filter(([key, value]) => {
        return value && typeof value !== 'object' && key !== '$$hashKey';
      })
      .map(([, value]) => value);
    return description.slice(0, 3).join(' / ');
  };

  $scope.getData = (crm = {}) => {
    return Object.entries(crm).slice(0, -2);
  };

  $scope.openCrm = crm => {
    if (typeof crm.open !== 'boolean') {
      crm.open = true;
    }
    crm.open = !crm.open;
  };

  $scope.validateIsObject = val => typeof val === 'object';

  const displaySuccessMessageCrmLinked = (entity: string) => {
    AdNotification.notify(
      $filter('translate')('METADATA_CRM_LINKED', {entity: $filter('translate')(entity)}),
      'success'
    );
  };

  const displaySuccessMessageCrmCreated = (entity: string) => {
    AdNotification.notify(
      $filter('translate')('METADATA_CRM_CREATED', {entity: $filter('translate')(entity)}),
      'success'
    );
  };

  const errorCrmManager = ({error, entity, crmName}) => {
    const {response: {error_code: code} = {}} = error || {};
    const errorMessages = crmErrorMessage.getMessage({
      code,
      locale: $scope.locale,
      entity,
      crmName
    });
    AdNotification.notify(errorMessages, 'error');
  };

  $scope.closeModal = () => {
    $uibModalInstance.close();
  };
}
