const defaultMessageTemplates = () => {
  return {
    user: {},
    templates: [],
    hsm: [],
    departments: [],
    filteredDepartments: [],
    editMode: false,
    template: null
  };
};
const messageTemplatesStore = new Vuex.Store({
  state: defaultMessageTemplates(),
  getters: {
    templates: state => {
      if (state.user.isSupervisor) {
        return state.templates.filter(template =>
          template.department_ids.some(id =>
            state.user.profile.departments.includes(id)
          )
        );
      }
      return state.templates;
    },
    departments: state => {
      let {departments} = state;
      if (state.user.isSupervisor) {
        departments = departments.filter(department =>
          state.user.profile.departments.includes(department.id)
        );
      }

      return departments.sort((sortA, sortB) => {
        return sortA.name.toLowerCase() > sortB.name.toLowerCase() ? 1 : -1;
      });
    },
    departmentsSelector: (state, getters) => {
      const filtered = getters.departments.map(({id, name}) => {
        return {
          name,
          id,
          active: true
        };
      });
      return filtered;
    },
    hsm: state => state.hsm,
    editMode: state => state.editMode,
    template: state => state.template,
    userIsSupervisor: state => state.user.isSupervisor,
    userIsAdmin: state => state.user.isAdmin

  },
  mutations: {
    MUTATE(state, {property, value}) {
      state[property] = value;
    }
  },
  actions: {
    notify(_context, {message, type}) {
      this.toast.notify(message, type);
    },
    addTemplate({commit}) {
      commit('MUTATE', {
        property: 'editMode',
        value: true
      });
    },
    editTemplate({commit}, payload) {
      commit('MUTATE', {
        property: 'editMode',
        value: true
      });
      commit('MUTATE', {
        property: 'template',
        value: payload
      });
    },
    closeEditor({commit}) {
      commit('MUTATE', {
        property: 'editMode',
        value: false
      });
      commit('MUTATE', {
        property: 'template',
        value: null
      });
    },
    createTemplate({dispatch}, payload) {
      return this.MessageTemplateService.createTemplate(payload).then(() => {
        dispatch('getTemplates');
      });
    },
    removeTemplate({dispatch}, id) {
      return this.MessageTemplateService.removeTemplate(id).then(() => {
        dispatch('getTemplates');
      });
    },
    downloadTemplates({ dispatch }) {
      return this.MessageTemplateService.downloadTemplate()
    },
    
    updateTemplate({dispatch}, payload) {
      const {resource_id, ...params} = payload;
      return this.MessageTemplateService.updateTemplate(
        resource_id,
        params
      ).then(() => {
        dispatch('getTemplates');
      });
    },
    updateTemplates({ dispatch }, payload) {
      return this.MessageTemplateService.updateTemplates(
        payload.key,
        payload.departments,
        payload.template
      ).then(response => {
        dispatch('getTemplates');
        return response;
      }).catch((error) => {
        console.error('Error updating templates:', error);
        throw error;
      });
    },
    getTemplates({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        this.MessageTemplateService.getCannedResponse()
          .then(({hsm, templates}) => {
            commit('MUTATE', {
              property: 'templates',
              value: templates
            });
            commit('MUTATE', {
              property: 'hsm',
              value: hsm
            });
            resolve({hsm, templates});
          })
          .catch(err => {
            dispatch('notify', {message: err.message, type: 'error'});
            reject(err);
          });
      });
    },
    getDepartments({commit}) {
      return new Promise((resolve, reject) => {
        this.DepartmentService.getDepartments()
          .then(departments => {
            commit('MUTATE', {
              property: 'departments',
              value: departments
            });
            resolve(departments);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getUser({commit}) {
      return new Promise((resolve, reject) => {
        this.UserService.getProfile()
          .then(user => {
            commit('MUTATE', {
              property: 'user',
              value: user
            });
            resolve(user);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
});
