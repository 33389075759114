'use strict';

function isNotEmptyNorFalse(constiable) {
  return constiable != null && constiable !== false;
}

function isNotEmpty(constiable) {
  return constiable != null;
}

function isNotEmptyList(list) {
  return isNotEmpty(list) && list.length > 0;
}

function cleanData(params) {
  // replace undefined by '' in object
  return Object.keys(params).reduce(
    (paramsObj, key) => ({...paramsObj, [key]: params[key] || ''}),
    {}
  );
};

class NewColumnModalController {
  adConstants
  AdAvailableSn
  AppStateService: AppStateService
  $document
  $filter
  $scope
  $timeout
  $rootScope
  UserData
  ColumnsService
  AdNotification
  FireTagService
  SocialAccountService: SocialAccountService
  EstablishmentService: EstablishmentService
  DepartmentService: DepartmentService
  CampaignService: CampaignService
  UserService: UserService
  WorkflowStatuses
  workflowStatusIndex = {};
  hotkeyHandler

  modalNewColumnOff

  private addTranslations() {
    this.$scope.multiSelectTranslations = {
      selectAll: this.$filter('translate')('MULTISELECT_SELECT_ALL'),
      selectNone: this.$filter('translate')('COLUMN_CASE_FILTER_NOT_USE'),
      reset: this.$filter('translate')('MULTISELECT_RESET'),
      search: this.$filter('translate')('MULTISELECT_SEARCH'),
      nothingSelected: this.$filter('translate')('COLUMN_CASE_FILTER_NOT_USE')
    };
  
    this.$scope.multiSelectCMTranslations = {
      selectAll: this.$filter('translate')('MULTISELECT_SELECT_ALL'),
      selectNone: this.$filter('translate')('MULTISELECT_SELECT_NONE'),
      reset: this.$filter('translate')('MULTISELECT_RESET'),
      search: this.$filter('translate')('MULTISELECT_SEARCH'),
      nothingSelected: this.$filter('translate')('COLUMN_CASE_FILTER_SELECT_CM')
    };
  }

  private groupAccountsBySocialNetwork(accounts) {
    const inputSocialAccounts = [];
    angular.forEach(_.groupBy(accounts, 'sn'), (value, key) => {
      // Add head
      inputSocialAccounts.push({
        name: "<strong class='capitalize'>" + key + '</strong>',
        channel: true
      });
      // Add body
      angular.forEach(value, (account, index) => {
        let ticked = false;
        angular.forEach(
          this.$scope.inputSocialAccounts,
          (exAccount, index) => {
            if (
              angular.isDefined(exAccount.idName) &&
              account.uid === exAccount.idName
            ) {
              ticked = exAccount.ticked;
            }
          }
        );
        inputSocialAccounts.push({
          idName: account.uid,
          name: this.$scope.data.getAccountName(account),
          image:
            '<img src="' +
            account.avatar +
            '" style="border-radius: 10px"/>',
          ticked: ticked
        });
      });
      // Add end
      inputSocialAccounts.push({
        channel: false
      });
    });
    this.$scope.inputSocialAccounts = inputSocialAccounts;
  }

  private setDefaultSelections() {
    this.$scope.data.selectedCM = null;
    this.$scope.data.selectedAccount = null;
    this.$scope.data.selectedColumnType = this.$scope.columnTypes[0].type;
    this.$scope.data.publishedBy = null;
  }

  private setDefaultFilters() {
    this.$scope.data.filterCommented = null;
    this.$scope.data.filterMentioned = null;
    this.$scope.data.filterPublished = null;
    this.$scope.data.filterCustomData = null;
    this.$scope.data.filterImportant = null;
    this.$scope.data.filterAnswered = null;
    this.$scope.data.filterUnAnswered = null;
    this.$scope.data.filterInSla = null;
    this.$scope.data.filterAddressed = null;
    this.$scope.data.filterCommunityManagers = null;
    this.$scope.data.filterDepartments = null;
    this.$scope.data.filterCampaigns = null;
    this.$scope.data.filterSortBy = 'updated_time';
    this.$scope.data.filterTickets = true;
    this.$scope.data.filterMessages = true;
    this.$scope.data.filterClient = true;
    this.$scope.data.filterComments = true;
  }

  private setDefaultsAutocomplete() {
    this.$scope.data.selectedUser = null;
    this.$scope.data.userSelectedText = '';
    this.$scope.data.autocompletedUsers = undefined;
  }

  private setDefaultsCommunityManagers() {
    if (this.$scope.setDefaultsCommunityManagers) {
      this.$scope.communityManagersFilterTypes = [
        {
          filter: null,
          label: this.$filter('translate')('COLUMN_CASE_FILTER_NOT_USE')
        },
        {
          filter: {filter_type: 'not_assigned'},
          label: this.$filter('translate')(
            'COLUMN_CASE_NO_ONE_COMMUNITY_MANAGER'
          )
        },
        {
          filter: {filter_type: 'any'},
          label: this.$filter('translate')('COLUMN_CASE_ANY_COMMUNITY_MANAGER')
        },
        {
          filter: {filter_type: 'non_bots'},
          label: this.$filter('translate')('COLUMN_CASE_HUMAN_COMMUNITY_MANAGER')
        },
        {
          filter: {filter_type: 'only_bots'},
          label: this.$filter('translate')('COLUMN_CASE_BOT_COMMUNITY_MANAGER')
        },
        this.$scope.whitelistFilterType
      ];

      const publishers = this.$scope.publisherList
        ? this.$scope.publisherList
        : angular.copy(this.$scope.communityManagers)
      publishers.unshift({
        cmid: true,
        name: this.$filter('translate')('COLUMN_CASE_ANY_COMMUNITY_MANAGER')
      });
      publishers.unshift({
        cmid: null,
        name: this.$filter('translate')('COLUMN_CASE_FILTER_NOT_USE')
      });
    }

    this.$scope.setDefaultsCommunityManagers = false;
  }

  private getColumnDate(key, column) {
    return new Date(column[key] * 1000);
  }

  private userToAutocomplete(user) {
    return {
      resource_id: user['resource_id'],
      sn: user.sn,
      text: user.uname,
      uid: user.uid
    };
  }

  public fromColumnToData(column, ckey, data, dkey) {
    if (isNotEmpty(column[ckey])) {
      data[dkey] = String(column[ckey]);
    }
  }

  private dateFromColumnToData(column, ckey, data, dkey) {
    if (isNotEmpty(column[ckey])) {
      data[dkey] = this.getColumnDate(ckey, column);
    }
  }

  private setMessagesDates(column, data) {
    this.dateFromColumnToData(column, 'from_date', data, 'dtFrom');
    this.dateFromColumnToData(column, 'to_date', data, 'dtTo');
  }

  private setCaseDates(column, data) {
    this.dateFromColumnToData(column, 'updated_from_date', data, 'dateFrom');
    this.dateFromColumnToData(column, 'updated_to_date', data, 'dateTo');
    if (data.dateFrom || data.dateTo) {
      data.caseDateRange = 'update';
    }
  }

  private setSocialNetworks(column, data) {
    if (isNotEmptyList(column['social_networks'])) {
      this.$scope.inputSocialNetworks
        .filter(item => column['social_networks'].includes(item.idName))
        .map(item => {
          item.ticked = true;
        });
    }
  }

  private setDepartments(column, data) {
    if (isNotEmptyList(column['departments'])) {
      this.$scope.inputDepartments.map(department => {
        department.ticked = column['departments'].includes(department.id);
      });
    }
  }

  private setCampaigns(column, data) {
    if (isNotEmptyList(column['campaigns'])) {
      this.$scope.inputCampaigns.map(campaign => {
        campaign.ticked = column['campaigns'].includes(campaign.id);
      });
    }
  }

  private setSocialAccounts(column, data) {
    this.updateAccounts();
    if (isNotEmptyList(column['social_accounts'])) {
      const selectedSocialAccounts = column['social_accounts'].map(
        item => item.uid
      );
      this.$scope.inputSocialAccounts
        .filter(item => selectedSocialAccounts.includes(item.idName))
        .map(item => {
          item.ticked = true;
        });
    }
  }

  private setAssignedCommunityManagers(column, data) {
    if (column['assignment'] != null) {
      this.$scope.data.filterCommunityManagers =
      this.$scope.communityManagersFilterTypes.find(
          it =>
            it.filter != null &&
            it.filter.filter_type === column['assignment'].filter_type
        ).filter;
    } else {
      this.$scope.data.filterCommunityManagers = null;
    }

    if (
      column['assignment'] != null &&
      column['assignment'].filter_type === 'whitelist' &&
      column['assignment'].community_managers != null
    ) {
      this.$scope.inputCommunityManagers
        .filter(item =>
          column['assignment'].community_managers.includes(item.cmid)
        )
        .map(item => {
          item.ticked = true;
        });
    }
  }

  private setPublishedByCM(column, data) {
    data.publishedBy = isNotEmpty(column['publisher'])
      ? column['publisher'].cmid
      : isNotEmpty(column['published'])
      ? column['published']
      : null;
  }

  private populateModalForMessage(column, data) {
    this.setSocialNetworks(column, data);
    this.setSocialAccounts(column, data);
    this.setPublishedByCM(column, data);
    this.setMessagesDates(column, data);
    this.setAssignedCommunityManagers(column, data);

    if (isNotEmpty(column.author)) {
      data.selectedUser = this.userToAutocomplete(column.author);
      data.userSelectedText = data.selectedUser.text;
      data.selectedUser.text =
        data.selectedUser.text + ' (' + data.selectedUser.sn + ')';
    }

    const params = {
      has_custom_data: 'filterCustomData',
      is_closed: 'filterClosed',
      kind: 'kind',
      is_mention: 'filterMentioned',
      answered: 'filterAnswered',
      in_sla: 'filterInSla',
      addressed: 'filterAddressed'
    };

    angular.forEach(params, (value, key) => {
      this.fromColumnToData(column, key, data, value)
    });
  }

  private populateModalForCase(column, data) {
    this.setSocialNetworks(column, data);
    this.setSocialAccounts(column, data);
    this.setAssignedCommunityManagers(column, data);
    this.setDepartments(column, data);
    this.setCampaigns(column, data);
    this.setCaseDates(column, data);

    if (isNotEmpty(column.author)) {
      data.selectedUser = this.userToAutocomplete(column.author);
      data.userSelectedText = data.selectedUser.text;
      data.selectedUser.text =
        data.selectedUser.text + ' (' + data.selectedUser.sn + ')';
    }

    const params = {
      commented: 'filterCommented',
      has_custom_data: 'filterCustomData',
      important: 'filterImportant',
      answered: 'filterAnswered',
      in_sla: 'filterInSla',
      addressed: 'filterAddressed',
    };

    angular.forEach(params, (value, key) => {
      this.fromColumnToData(column, key, data, value)
    });

    let workflowStatus = undefined;

    if (column['workflow_status']) {
      // required to match selected using ng-options
      workflowStatus = this.$scope.workflowStatuses.find(status => {
        return status.resource_id === column['workflow_status'].resource_id;
      })
    } else {
      // simulate one from flags if any
      if (column['is_closed'] === true) {
        workflowStatus =
          this.workflowStatusIndex[this.adConstants.WFS_CATEGORY_CLOSED];
      } else if (column['is_closed'] === false) {
        workflowStatus =
          this.workflowStatusIndex[this.adConstants.WFS_CATEGORY_OPENED];
      }
    }
    data['filterWorkflowStatus'] = workflowStatus;
  }

  private setDataFromColumn(column) {
    this.$scope.data.columnName = column.name;
    if (angular.isDefined(column.pagination_field)) {
      this.$scope.data.filterSortBy = column.pagination_field;
    }

    if (column.keyword) {
      this.$scope.data.keyword = column.keyword;
    }

    if (isNotEmpty(column['column_type'])) {
      this.$scope.data.selectedColumnType = column['column_type'];
    }

    if (column['column_type'] === 'message') {
      this.populateModalForMessage(column, this.$scope.data);
    } else if (column['column_type'] === 'case') {
      this.populateModalForCase(column, this.$scope.data);
    }
  }

  private setDefaults() {
    this.$scope.loading = false;
    this.$scope.data = {};
    this.$scope.data.getAccountName = account => {
      return account.sn === 'twitter' && account.type === 'user'
        ? '@' + account.username
        : ['facebook', 'whatsapp', 'chat'].indexOf(account.sn) !== -1
        ? account.name
        : account.username;
    };
    this.setDefaultSelections();
    this.setDefaultFilters();
    this.setDefaultsAutocomplete();
    this.setDefaultsCommunityManagers();

    const sns = new Set(this.$scope.accounts.map(account => account.sn));
    this.$scope.inputSocialNetworks = Array.from(sns).map(sn => ({
      idName: sn,
      name: sn.replace('_', ' '),
      icon:
        '<i class="fa column__sn_icon ' + this.AdAvailableSn.getIcon(sn) + '"/>',
      ticked: false
    }));
    this.$scope.outputSocialNetworks = [];

    this.$scope.inputSocialAccounts = [];
    this.groupAccountsBySocialNetwork(this.$scope.accounts);
    this.$scope.outputSocialAccounts = [];

    this.$scope.inputCommunityManagers = this.$scope.communityManagers.map(
      cm => ({
        cmid: cm.cmid,
        name: cm.name,
        ticked: false
      })
    );
    this.$scope.outputCommunityManagers = [];

    this.$scope.inputDepartments = this.getInputDepartments();
    this.$scope.outputDepartments = [];

    this.$scope.inputCampaigns = [];

    this.getInputCampaigns().then(campaigns => {
      this.$scope.inputCampaigns = campaigns;
    });

    this.$scope.outputCampaigns = [];
    this.$scope.data.dtTo = null;
    this.$scope.data.dtFrom = null;
    this.$scope.data.updatedFrom = null;
    this.$scope.data.updatedTo = null;

    if (this.$scope.action === 'Export') {
      const days = 7;
      const now = new Date();
      const daysAgo = new Date(new Date().setDate(now.getDate() - days));
      this.$scope.data.dtTo = now;
      this.$scope.data.dateTo = now;
      this.$scope.data.updatedTo = now;
      this.$scope.data.dtFrom = daysAgo;
      this.$scope.data.dateFrom = daysAgo;
      this.$scope.data.updatedFrom = daysAgo;
    }

    this.$scope.data.columnName = undefined;
    this.$scope.data.keyword = undefined;
    this.$scope.data.caseDateRange =
      this.$scope.action === 'Export' ? 'creation' : 'update';

    if (this.$scope.column !== undefined) {
      this.setDataFromColumn(this.$scope.column);
    }

    this.handleTeamsMode();
  }

  private handleTeamsMode() {
    if (this.$scope.supervisorEnabled && !this.$scope.isAdmin) {
      this.$scope.data.filterCommunityManagers = this.$scope.whitelistFilterType.filter;
    }
  }

  private tickDepartments(departments, shouldTick) {
    return departments.map(department => ({
      id: department.id,
      name: department.name,
      ticked: shouldTick
    }));
  }

  private tickCampaigns(campaigns, shouldTick) {
    return campaigns.map(campaign => ({
      id: campaign.id,
      name: campaign.visual_name,
      status: campaign.status,
      ticked: shouldTick
    }));
  }

  private getInputDepartments() {
    let departments;
    let shouldTick = false;
    if (
      this.$scope.supervisorEnabled &&
      !this.$scope.isAdmin &&
      this.$scope.userDepartments.length > 0
    ) {
      departments = this.$scope.departments.filter(department => {
        return this.$scope.userDepartments.includes(department.id);
      });
      shouldTick = true;
    } else {
      departments = this.$scope.departments;
    }

    return this.tickDepartments(departments, shouldTick);
  }

  private getInputCampaigns() {
    return this.CampaignService.getCampaigns().then(campaigns => {
      const shouldTick = this.$scope.supervisorEnabled && !this.$scope.isAdmin;
      return this.tickCampaigns(campaigns, shouldTick);
    });
  }
  

  private initModal() {
    if (this.$scope.data.selectedColumnType === 'case') {
      this.validateCaseColumn();
    } else {
      this.validateMessageColumn();
    }
  setTimeout(
        () => document.querySelector('.modal-body .form-control').focus(),
        300
    );
  }

  private selectItem(selected) {
    if (selected[0] === '@') {
      selected = selected.substring(1);
    }
    const autocompletedUsers = this.$scope.autocompleteFullUsers;
    this.$scope.data.selectedUser = _.filter(
      autocompletedUsers,
      user => {
        return user.text + ' (' + user.sn + ')' === selected;
      }
    )[0];
    this.$scope.data.selectedUser.text +=
      ' (' + this.$scope.data.selectedUser.sn + ')';
  }

  private loadItems(query) {
    if (
      query === undefined ||
      query.length < 4 ||
      (query[0] === '@' && query.length < 5)
    ) {
      this.$scope.data.loadingUsers = false;
      this.$scope.data.noResults = false;
      return;
    }

    this.$scope.data.loadingUsers = true;
    this.$scope.data.noResults = false;
    const promise = this.UserData.loadAutocomplete(query);
    promise.then(
      data => {
        this.$scope.data.loadingUsers = false;
        this.$scope.autocompleteFullUsers = data;
        this.$scope.data.autocompletedUsers = _.map(data, user => {
          return query[0] === '@'
            ? '@' + user.text + ' (' + user.sn + ')'
            : user.text + ' (' + user.sn + ')';
        });

        this.$scope.data.noResults = this.$scope.data.autocompletedUsers.length === 0;
      },
      error => {
        this.$scope.data.loadingUsers = false;
        if (error.status === 404) {
          this.$scope.data.autocompletedUsers = [];
          this.$scope.data.noResults = true;
        }
      }
    );
  }

  private hotkeys(event) {
    switch (event.keyCode) {
      case 13: // enter
        if (!this.$scope.isColumnAddFormInvalid) {
          this.$scope.$apply(this.$scope.submitColumnAddForm);
        }
        break;
      case 27: // escape
        this.$scope.$apply(this.close());
        break;
    }
  }

  public handleModalEvent(action, column) {
    this.$scope.column = angular.copy(column);
    this.$scope.action = action;
    this.setDefaults();
    this.$scope.show = true;
    this.$scope.showDates = true;
    this.$scope.isExport = action === 'Export';

    if (this.$scope.isExport) {
      this.$scope.namePlaceHolder = this.$filter('translate')(
        'COLUMN_CASE_FILTER_COLUMN_NAME_EXPORT'
      );
    } else {
      this.$scope.namePlaceHolder = this.$filter('translate')(
        'COLUMN_CASE_FILTER_COLUMN_NAME'
      );
    }

    this.$scope.data.autocompletedUsers = undefined
    this.$scope.data.loadItems = query => this.loadItems(query)
    this.$scope.data.selectItem = selected => this.selectItem(selected)

    this.$scope.data.deselectUser = () => {
      this.setDefaultsAutocomplete()
    }
    this.$scope.channelItemClick = () => {
      this.updateAccounts();
    }

    this.$document.on('keydown', this.hotkeyHandler);
    this.initModal();
  }

  private addEventListeners() {
    this.modalNewColumnOff = this.$rootScope.$on(
      'modalNewColumn',
      (event, action, column) => this.handleModalEvent(action, column)
    );

    this.$scope.$on('$destroy', () => this.modalNewColumnOff())
  }

  private postColumn(data) {
    const action = 'post_column';
    data['audio'] = this.$scope.column.audio;
    data['toast'] = this.$scope.column.toast;

    this.ColumnsService.postColumn(data).then(
      column => {
        this.$scope.$emit('addColumn', column);
        this.close();
        this.setDefaults();
      },
      data => {
        this.$scope.loading = false;
        this.AdNotification.error(data, action);
      }
    );
  }

  private validateDate(dateA, dateB, action) {
    const threeMonths = 100 * 24 * 60 * 60 * 1000;
    if (isNotEmpty(dateA) && isNotEmpty(dateB)) {
      const difference = dateB.getTime() - dateA.getTime();
      return 0 >= difference
        ? this.$scope.dateRangeStatus.invalid
        : action === 'Export' &&
          !this.$scope.bigExport &&
          difference > threeMonths
        ? this.$scope.dateRangeStatus.tooBroad
        : this.$scope.dateRangeStatus.valid; //Time interval to query cannot be longer than a bit over 3 months.
    } else {
      return action === 'Export'
        ? this.$scope.dateRangeStatus.unspecified
        : this.$scope.dateRangeStatus.valid;
    }
  }

  private validateMessageColumn() {
    const dtFrom = this.$scope.data.dtFrom;
    const dtTo = this.$scope.data.dtTo;
    this.$scope.data.dateRangeStatus = this.validateDate(dtFrom, dtTo, this.$scope.action);
    this.$scope.isColumnAddFormInvalid =
    this.$scope.data.dateRangeStatus !== this.$scope.dateRangeStatus.valid ||
      (this.$scope.data.filterCommunityManagers != null &&
        this.$scope.data.filterCommunityManagers.filter_type === 'whitelist' &&
        this.$scope.inputCommunityManagers.every(it => !it.ticked));
  };

  private validateCaseColumn() {
    const dateFrom = this.$scope.data.dateFrom;
    const dateTo = this.$scope.data.dateTo;
    this.$scope.data.dateRangeStatus = this.validateDate(
      dateFrom,
      dateTo,
      this.$scope.action
    );
    this.$scope.isColumnAddFormInvalid =
    this.$scope.data.dateRangeStatus !== this.$scope.dateRangeStatus.valid ||
      (this.$scope.data.filterCommunityManagers != null &&
        this.$scope.data.filterCommunityManagers.filter_type === 'whitelist' &&
        this.$scope.inputCommunityManagers.every(it => !it.ticked)) ||
      (this.$scope.supervisorEnabled &&
        !this.$scope.isAdmin &&
        this.$scope.userDepartments.length > 0 &&
        this.$scope.inputDepartments != null &&
        this.$scope.inputDepartments.every(it => !it.ticked));
  };

  private exportColumn(data) {
    const action = 'export_column';

    this.ColumnsService.exportColumn(data).then(
      column => {
        this.AdNotification.success(201, action);
        this.$scope.$emit('addExportColumn', column);
        const hasLimits = this.$scope.establishment.plan.limits.find(
          limit_max => limit_max !== -1
        );
        if (hasLimits) {
          this.$rootScope.$emit('updateExportLimits');
        }

        this.close();
        this.setDefaults();

        if (this.$scope.bigExport === true) {
          this.$scope.establishment.config.big_export = false;
          this.$scope.bigExport = false;
        }

        const params = cleanData(data);
        this.FireTagService.setEvent({
          name: 'exportacion_columna',
          params
        });
      },
      data => {
        this.$scope.loading = false;
        this.AdNotification.error(data, action);

        if (this.$scope.bigExport === true) {
          this.$scope.establishment.config.big_export = false;
          this.$scope.bigExport = false;
        }
        this.initModal();
      }
    );
  }

  private editColumn(data) {
    const idColumn = this.$scope.column['resource_id'];
    const action = 'edit_column';
    data['audio'] = this.$scope.column.audio;
    data['toast'] = this.$scope.column.toast;
    data['asc_items'] = this.$scope.column['asc_items'];
    data['autorefresh'] = this.$scope.column.autorefresh;

    if (this.$scope.column.order >= 0) {
      data['order'] = this.$scope.column.order;
    }

    this.ColumnsService.updateColumn(idColumn, data).then(
      column => {
        this.$scope.$emit('editColumn', column);
        this.close();
        this.setDefaults();
      },
      data => {
        this.$scope.loading = false;
        this.AdNotification.error(data, action);
      }
    );
  }

  private getInputConfiguration() {
    const columnType = this.$scope.data.selectedColumnType;
    return columnType === 'message'
      ? this.readModalMessageConfiguration()
      : this.readModalCaseConfiguration();
  }

  private autocompleteToUser(user) {
    return {id: user['resource_id']};
  }

  private fromDataToColumn(data, dataKey, columnData, columnKey) {
    if (isNotEmpty(data[dataKey])) {
      columnData[columnKey] = data[dataKey];
    }
  }

  private fromDataTextToColumnBoolean(
    data,
    dataKey,
    columnData,
    columnKey
  ) {
    columnData[columnKey] =
      data[dataKey] === 'true'
        ? true
        : data[dataKey] === 'false'
        ? false
        : undefined;
  }

  private fromDataToColumnDate(data, dataKey, columnData, columnKey) {
    if (isNotEmpty(data[dataKey])) {
      columnData[columnKey] = data[dataKey].getTime() / 1000;
    }
  }

  private collectCommonData(source, target) {
    this.fromDataToColumn(source, 'columnName', target, 'name');
    this.fromDataToColumn(source, 'selectedColumnType', target, 'column_type');

    // FIXME: We should really be using outputSocialNetworks, as it should be already filtered, but it doesn't seem to
    //  be getting filled. Filtering explicitly for now (anyway, we should abandon AngularJS, maybe we'll use a
    //  different library)
    target['social_networks'] = this.$scope.inputSocialNetworks
      .filter(it => it.ticked)
      .map(socialNetwork => socialNetwork.idName);

    const filteredAccounts = this.$scope.inputSocialAccounts.filter(
      it => it.ticked
    );
    if (isNotEmptyList(filteredAccounts)) {
      target['social_accounts'] = filteredAccounts.map(account => ({
        sn: _.find(this.$scope.accounts, acc => {
          return acc.uid === account.idName;
        }).sn,
        uid: account.idName
      }));
    }
  }

  private collectCommonDataDates(source, target) {
    this.fromDataToColumnDate(source, 'dtFrom', target, 'from_date');
    this.fromDataToColumnDate(source, 'dtTo', target, 'to_date');
  }

  private readPublishedConfiguration(source, target) {
    target.published = isNotEmpty(source.publishedBy)
      ? Boolean(source.publishedBy)
      : undefined;
    target.publisher =
      target.published && typeof source.publishedBy === 'number'
        ? {cmid: source.publishedBy}
        : undefined;
  }

  private readAssignedConfiguration(source, target) {
    if (source.filterCommunityManagers == null) {
      target.assigned = undefined;
      return;
    }

    // Just in case there's no type.
    target.assigned =
      source.filterCommunityManagers.filter_type != null
        ? source.filterCommunityManagers.filter_type !== 'not_assigned'
        : undefined;

    target.assignment = {
      filter_type: source.filterCommunityManagers.filter_type
    };

    // FIXME: this should come from source.filterCommunityManagers.community_managers,
    //  but it doesn't seem to be getting the data. We filter explicitly.
    if (target.assignment.filter_type === 'whitelist') {
      target.assignment.community_managers = this.$scope.inputCommunityManagers
        .filter(it => it.ticked)
        .map(it => it.cmid);
    }
  }

  private readUserConfiguration(source, target) {
    if (isNotEmptyNorFalse(source.selectedUser)) {
      target.author = this.autocompleteToUser(source.selectedUser);
    } else {
      target.author = undefined;
    }
  }

  private readDepartmentsConfiguration(source, target) {
    let departments = this.$scope.inputDepartments
      .filter(it => it.ticked)
      .map(it => it.id);
    if (isNotEmptyList(departments)) {
      target.departments = departments;
    }
  }

  private readCampaignsConfiguration(source, target) {
    let campaigns = this.$scope.inputCampaigns
      .filter(it => it.ticked)
      .map(it => it.id);
    if (isNotEmptyList(campaigns)) {
      target.campaigns = campaigns;
    }
  }

  private readModalCaseConfiguration() {
    const config = {};
    this.collectCommonData(this.$scope.data, config);
    this.fromDataToColumn(
      this.$scope.data,
      'filterSortBy',
      config,
      'pagination_field'
    );
    const params = {
      filterCommented: 'commented',
      filterCustomData: 'has_custom_data',
      filterImportant: 'important',
      filterAnswered: 'answered',
      filterInSla: 'in_sla',
      filterAddressed: 'addressed',
    };
    angular.forEach(params, (value, key) => {
      this.fromDataTextToColumnBoolean(this.$scope.data, key, config, value);
    });

    config['workflow_status'] = this.$scope.data['filterWorkflowStatus'];

    let dateParams;
    switch (this.$scope.data.caseDateRange) {
      case 'creation':
        dateParams = {
          dateFrom: 'created_from_date',
          dateTo: 'created_to_date'
        };
        break;
      case 'update':
        dateParams = {
          dateFrom: 'updated_from_date',
          dateTo: 'updated_to_date'
        };
        break;
    }

    angular.forEach(dateParams, (value, key) => {
      this.fromDataToColumnDate(this.$scope.data, key, config, value);
    });

    this.readAssignedConfiguration(this.$scope.data, config);
    this.readUserConfiguration(this.$scope.data, config);
    this.readDepartmentsConfiguration(this.$scope.data, config);
    this.readCampaignsConfiguration(this.$scope.data, config);

    return config;
  }

  private readModalMessageConfiguration() {
    const target = {};

    this.collectCommonData(this.$scope.data, target);
    this.collectCommonDataDates(this.$scope.data, target);
    this.fromDataToColumn(this.$scope.data, 'kind', target, 'kind');

    const params = {
      filterAnswered: 'answered',
      filterMentioned: 'is_mention'
    };

    angular.forEach(params, (fieldName, dataKey) => {
      this.fromDataTextToColumnBoolean(this.$scope.data, dataKey, target, fieldName);
    });

    this.readPublishedConfiguration(this.$scope.data, target);
    this.readAssignedConfiguration(this.$scope.data, target);
    this.readUserConfiguration(this.$scope.data, target);

    return target;
  }

  private close () {
    this.$scope.show = false;
    this.$document.off('keydown', this.hotkeyHandler);
    this.$timeout(() => {
      this.$scope.data = {};
    }, 500);
  };

  private submitColumnForm() {
    this.$scope.loading = true;
    const data = this.getInputConfiguration();
    
    data['ticket_export'] = this.$scope.data.filterTickets ? true : false
    data['message_export'] = this.$scope.data.filterMessages ? true : false
    data['client_export'] = this.$scope.data.filterClient ? true : false
    data['comment_export'] = this.$scope.data.filterComments ? true : false


    let gotoInbox = true;
    if (this.$scope.action === 'Export') {
      this.exportColumn(data);
      gotoInbox = false;
    } else if (this.$scope.action === 'Add') {
      this.postColumn(data);
    } else if (this.$scope.action === 'Edit') {
      this.editColumn(data);
    }
    if(gotoInbox)
        this.AppStateService.goToInbox();
  };
    

  private updateAccounts = function () {
    const selectedSns = this.$scope.inputSocialNetworks
      .filter(it => it.ticked)
      .map(item => item.idName);
    if (isNotEmptyList(selectedSns)) {
      const filter = this.$scope.accounts.filter(account =>
        selectedSns.includes(account.sn)
      );
      this.groupAccountsBySocialNetwork(filter);
    } else {
      this.groupAccountsBySocialNetwork(this.$scope.accounts);
    }
  }

  private populateWorkflowStatuses($scope, workflowStatuses) {
    workflowStatuses.forEach(status => {
      this.workflowStatusIndex[status.category] = status
    })
    if (!$scope.isWorkflowEnabled) {
      // Only Opened/Closed/Ignored are supported without workflow statuses
      workflowStatuses = workflowStatuses.filter(status => {
        return status.category !== this.adConstants.WFS_CATEGORY_IN_PROGRESS
      });
    }
    $scope.workflowStatuses = workflowStatuses;
  }

  private loadDataIntoScope($scope) {
    Promise.all([
      this.SocialAccountService.getSocialAccounts(),
      this.EstablishmentService.getEstablishment(),
      this.UserService.getProfile(),
      this.UserService.getAllUsers(),
      this.DepartmentService.getDepartments(),
      this.WorkflowStatuses.getAll()
    ]).then(
      ([accounts, establishment, user, users, departments, workflowStatuses]) => {
      $scope.accounts = accounts
      $scope.establishment = establishment
      $scope.departments = departments
      $scope.isWorkflowEnabled = establishment.config.workflow_enabled
      $scope.supervisorEnabled = establishment.features.supervisor_role
      $scope.isAnalystOrAdmin = user.isAnalystOrAdmin
      $scope.isAdmin = user.isAdmin
      $scope.isCm = user.isCm
      $scope.isSupervisor = user.isSupervisor
      $scope.userDepartments = user.profile.departments
      $scope.communityManagers = users

      if ($scope.supervisorEnabled && !$scope.isAdmin) {
        let department_id = $scope.userDepartments[0];
        $scope.communityManagers = $scope.communityManagers.filter(cm => {
          return $scope.userDepartments.some(department_id => cm['departments'].includes(department_id))
        })
      }
  
      $scope.bigExport = establishment.config.big_export

      this.populateWorkflowStatuses($scope, workflowStatuses)
    })
  }

  constructor(
    $scope,
    $rootScope,
    $filter,
    $timeout,
    UserData,
    AdNotification,
    AppStateService: AppStateService,
    ColumnsService,
    $document,
    WorkflowStatuses,
    adConstants,
    AdAvailableSn,
    FireTagService,
    SocialAccountService: SocialAccountService,
    EstablishmentService: EstablishmentService,
    DepartmentService: DepartmentService,
    CampaignService: CampaignService,
    UserService: UserService
  ) {
    this.$filter = $filter
    this.$scope = $scope
    this.$rootScope = $rootScope
    this.$document = $document
    this.$timeout = $timeout
    this.AdAvailableSn = AdAvailableSn
    this.AdNotification = AdNotification
    this.adConstants = adConstants
    this.UserData = UserData
    this.AppStateService = AppStateService
    this.ColumnsService = ColumnsService
    this.FireTagService = FireTagService
    this.SocialAccountService = SocialAccountService
    this.EstablishmentService = EstablishmentService
    this.DepartmentService = DepartmentService
    this.CampaignService = CampaignService
    this.UserService = UserService
    this.WorkflowStatuses = WorkflowStatuses
    this.hotkeyHandler = (event) => this.hotkeys(event)

    $scope.views = {
      message: 'blocks/column/views/column_message_tab.html'
    };

    $scope.kinds = [
      {kind: 'message', name: 'public'},
      {kind: 'private', name: 'private'}
    ];

    $scope.action = 'Add';
    $scope.show = false;
    $scope.column = {};
    $scope.data = {};
    $scope.whitelistFilterType = {
      filter: {
        filter_type: 'whitelist',
        community_managers: []
      },
      label: this.$filter('translate')(
        'COLUMN_CASE_FILTER_MULTIPLE_COMMUNITY_MANAGERS'
      )
    }

    $scope.dateRangeStatus = {
      valid: 'valid',
      unspecified: 'unspecified',
      invalid: 'invalid',
      tooBroad: 'tooBroad'
    };

    this.addEventListeners()
    this.addTranslations()
    this.loadDataIntoScope($scope)

    $scope.publisherList = angular.copy($scope.communityManagers);

    $scope.columnTypes = $rootScope.columnTypes;
    $scope.setDefaultsCommunityManagers = true;

    $scope.validateMessageColumn = () => this.validateMessageColumn()
    $scope.validateCaseColumn = () => this.validateCaseColumn()
    $scope.close = () => this.close()
    $scope.submitColumnAddForm = () => this.submitColumnForm()

    $scope.ignoreHotkeys = function ($event) {
      $event.stopPropagation();
    };

    $scope.getSnName = function (sn) {
      return sn.replace('_', ' ');
    };

    $scope.selectOpenWorkflowStatus = function () {
      $scope.workflowStatuses.forEach(status => {
        if (status.category == 0 && status.kind == 0 && status.core) {
          $scope.data.filterWorkflowStatus = status;
        }
      })  
    };

    $scope.checkRestrictions = function () {
      if ($scope.data.filterSortBy === 'last_message_time') {
        // Force waiting for answer if it's sorting by waiting time
        $scope.data.filterAnswered = 'false';
      } else if ($scope.data.filterSortBy === 'sla_expiration_time') {
        // Force open state and not addressed if it's sorting by sla expiration
        $scope.selectOpenWorkflowStatus();
        $scope.data.filterAddressed = 'false';
      }
    };

    const SelectecColumnTypeWatcherOff = $scope.$watch('data.selectedColumnType', function (columnType) {
      if ($scope.data.selectedColumnType === 'case') {
        $scope.validateCaseColumn();
      } else {
        $scope.validateMessageColumn();
      }
    });

    function watchDateExpression(scope) {
      return {
        dtFrom: scope.data.dtFrom,
        dtTo: scope.data.dtTo
      };
    }

    const dateExpressionWatcherOff = $scope.$watch(
      watchDateExpression,
      function (newValue, oldValue) {
        if (newValue !== oldValue) {
          if ($scope.data.selectedColumnType === 'message') {
            $scope.validateMessageColumn();
          }
        } else {
          $scope.isColumnAddFormInvalid = $scope.action === 'Export';
        }
      },
      true
    );

    function watchDateCaseExpression(scope) {
      return {
        dateFrom: scope.data.dateFrom,
        dateTo: scope.data.dateTo
      };
    }

    const dateCaseExpressionWatcherOff = $scope.$watch(
      watchDateCaseExpression,
      function (newValue, oldValue) {
        if (newValue !== oldValue) {
          if ($scope.data.selectedColumnType === 'case') {
            $scope.validateCaseColumn();
          }
        } else {
          $scope.isColumnAddFormInvalid = $scope.action === 'Export';
        }
      },
      true
    );

    $scope.$on('$destroy', function () {
      dateExpressionWatcherOff();
      dateCaseExpressionWatcherOff();
    });
  }

}

angular
  .module('postCenterWebClientApp')
  .controller(
    'NewColumnModalCtrl',
    NewColumnModalController
  );
