/* eslint-disable prefer-arrow-callback */
'use strict';

ReplyMessageCtrl.$inject = [
  '$scope',
  '$rootScope',
  '$filter',
  '$timeout',
  '$sce',
  'AdNotification',
  'Message',
  '$element',
  'AderesoFilename',
  'FireTagService',
  'MessageTemplateService',
  'snErrorMessage',
  'EstablishmentService',
  'UserService'
];

function ReplyMessageCtrl(
  $scope,
  $rootScope,
  $filter,
  $timeout,
  $sce,
  AdNotification: AdNotification,
  messageService: MessageService,
  $element,
  AderesoFilename,
  FireTagService,
  MessageTemplateService,
  snErrorMessage,
  EstablishmentService: EstablishmentService,
  UserService: UserService
) {
  var self = this;
  var action = 'reply';
  var file_upload_action = 'file_upload';
  var TWITTER_PUBLICATION_MAX_CHAR = 280;
  var TWITTER_DM_MAX_CHAR = 10000;
  var PLAY_STORE_MAX_CHAR = 350;
  var FACEBOOK_DM_MAX_CHAR = 20000;
  var FACEBOOK_PUBLICATION_MAX_CHAR = 63206;
  var FACEBOOK_PUBLIC_COMMENT_MAX_CHAR = 8000;
  var INSTAGRAM_DM_MAX_CHAR = 1000;
  var INSTAGRAM_PUBLICATION_MAX_CHAR = 2200;
  var INSTAGRAM_PUBLIC_COMMENT_MAX_CHAR = 2200;
  var WHATSAPP_DM_MAX_CHAR = 65536;
  var count_url_size = 24;

  let SpeechRecognition = window.SpeechRecognition       ||
                          window.webkitSpeechRecognition ||
                          null;
  let recognition = null;

  $scope.transcriptionEnabled = SpeechRecognition !== null;

  $scope.loading = false;
  $scope.showControls = true;

  $scope.recordingAudio = false;
  $scope.audioRecorded = false;
  $scope.recordedAudioUrl = null;
  $scope.hasTranscription = false;
  $scope.audioTranscription = $filter('translate')('LOADING_TRANSCRIPTION');
  $scope.audioTranscriptionConfidence = 0;
  $scope.lang = 'es-ES';

  interface TicketDraft {
    text: string;
  }

  const ticketDrafts = 'ticketDrafts';
  let existingDrafts: Record<string, TicketDraft> = {}

  const currentCase = $rootScope.caseId;

  function initializeText() {
    try {
      existingDrafts = JSON.parse(localStorage.getItem(ticketDrafts) || '{}');
      const draft: TicketDraft = existingDrafts[currentCase] || {text: ''};
      $scope.text = draft.text;
    } catch {
      $scope.text = '';
    }
  }

  initializeText();

  $scope.cannedResponsesParams = {};
  MessageTemplateService.getTemplates().then(templates => {
    $scope.cannedResponses = templates
  })
  $scope.missingTwitterTargetName = false;
  $scope.maxChar = getMaxChar();
  $scope.uploadedDocumentFiles = [];
  $scope.textAreaId = 'replytextarea-' + $scope.$id;

  EstablishmentService.getEstablishment().then(establishment => {
    $scope.blockMedia = establishment.config.block_outbound_media
    $scope.allowedExtension = establishment.config.outbound_allowed_extensions
    $scope.outboundAudio = establishment.config.outbound_audio
    $scope.accountsBlockingMedia = establishment.config.accounts_blocking_outbound_media
    $scope.acceptMedia = '.' + $scope.allowedExtension.join(', .')
  })

  UserService.getProfile().then(user => {
    $scope.user = user
    const language = user.profile.language

    const languages = {
      'es': 'es-ES',
      'pt': 'pt-BR',
      'en': 'en-US'
    }

    const lang = languages[language] || language;

    $scope.lang = lang;
    setupRecognitionAfterProfileLoaded();
    const placeholder = $filter('translate')(
      'MESSAGECONTROL_REPLY_CANNED_RESPONSE_PLACEHOLDER'
    );
    $scope.options = {
      height: 200,
      focus: true,
      dialogsInBody: true,
      placeholder,
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['insert', ['link', 'table']],
        ['fontsize', ['fontsize']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['fontname', ['fontname']]
      ],
      shortcuts: {},
      lang
    }
  })
  
  self.recipients = [];
  $scope.quotedMessage = null;
  $scope.editor = {};
  $scope.summernoteText = '';
  $scope.onInit = () => {};
  $scope.editable = {};
  $scope.onFocus = () => {
    $scope.showControls = true;
  };
  $scope.onBlur = e => {};
  $scope.keyDown = e => {
    $scope.publishIfCtrlEnter(e);
  };
  $scope.fileUpload = file => {
    // image upload as a file
    const fileType = file[0].type.split('/')[1];
    if ($scope.blockMedia) {
      if ($scope.allowedExtension.includes(fileType)) {
        $scope.onFileSelect(file[0], 'document');
      }
    } else {
      $scope.onFileSelect(file[0], 'document');
    }
  };
  $scope.onChange = contents => {
    $scope.text = contents;
  };
  function getPlainText() {
    const text = document.createElement('div');
    text.innerHTML = $scope.text;
    let plainText = '';
    if (text.childNodes.length !== 0) {
      text.childNodes.forEach(node => {
        plainText += `${node.innerText} `;
      });
    }
    return plainText.trim();
  }
  function isEmpty(text) {
    var empty = text === undefined;
    empty = empty || (!empty && text.trim().length === 0);
    if ($scope.replyToMessage.sn === 'email') {
      var hasAttachment = $scope.uploadedDocumentFiles.length > 0;
      empty = empty && !hasAttachment;
    }
    return empty;
  }

  function clearAfterPublish() {
    var target = angular.element('#' + $scope.textAreaId);
    target.css('height', '0');
    $scope.cleanFiles();
    $scope.text = '';
    $scope.maxChar = getMaxChar();
    if ($scope.replyForm && $scope.replyForm.text) {
      $scope.replyForm.text.$setPristine();
    }
    configureReply();
  }

  function forwardEmailIsOnRecipient(account, recipient) {
    if (account.config !== undefined) {
      if (account.config.forward_email !== undefined) {
        if (account.config.forward_email === recipient.uid) {
          return true;
        }
      }
    }
    return false;
  }

  function isAccountOnRecipients(account) {
    var recipients = $scope.replyToMessage.recipients;
    for (var i = 0; i < recipients.length; i++) {
      var recipient = recipients[i];
      if (account.uid === recipient.uid) {
        return true;
      } else if (recipient.sn === 'email' && recipient.sn === account.sn) {
        if (forwardEmailIsOnRecipient(account, recipient)) {
          return true;
        }
      } else if (
        recipient.uid.includes('twitter-search-') &&
        recipient.sn === account.sn &&
        recipient.sn === 'twitter'
      ) {
        return true;
      }
    }
    return false;
  }

  function getMaxChar() {
    var sn = $scope.replyToMessage.sn;
    var kind = $scope.replyToMessage.kind;
    var is_private = kind === 'private';

    if (sn === 'twitter' && is_private) {
      return TWITTER_DM_MAX_CHAR;
    } else if (sn === 'twitter' && is_private === false) {
      return TWITTER_PUBLICATION_MAX_CHAR;
    } else if (sn === 'whatsapp' && is_private) {
      return WHATSAPP_DM_MAX_CHAR;
    } else if (sn === 'instagram' && is_private) {
      return INSTAGRAM_DM_MAX_CHAR;
    } else if (sn === 'instagram' && is_private === false) {
      return INSTAGRAM_PUBLIC_COMMENT_MAX_CHAR;
    } else if (sn === 'facebook' && is_private) {
      return FACEBOOK_DM_MAX_CHAR;
    } else if (sn === 'facebook' && is_private === false) {
      return FACEBOOK_PUBLIC_COMMENT_MAX_CHAR;
    } 
    else {
      return Infinity;
    }
  }

  $scope.canMultiAttach = function () {
    return $scope.replyToMessage.sn === 'email';
  };

  addEventListener('emoji-click', (event) => {

    let emojiUnicode = event.detail.emoji.unicode;

    var target = angular.element('#' + $scope.textAreaId);

    let currentText = target.prop('value')

    let selectionStart = target.prop('selectionStart')
    let selectionEnd = target.prop('selectionEnd')

    let newText = '';

    if (selectionStart || selectionStart == '0') {

      newText = currentText.substring(0, selectionStart) 
        + emojiUnicode
        + currentText.substring(selectionEnd, currentText.length);

    } else {
      newText = currentText + emojiUnicode;
    }
    target.focus();

    target.prop('value', newText);
    target.prop('selectionStart', selectionStart + emojiUnicode.length);
    target.prop('selectionEnd', selectionEnd + emojiUnicode.length);
    $scope.text = newText;
    $scope.$apply();
  });

  $scope.defaultAccountSelector = function (accounts) {
    if (accounts && accounts.length > 0) {
      accounts[0].selected = true;
    }
  };

  $scope.accountAbleToPost = function (account) {
    var replyTo = $scope.replyToMessage;

    if (account.type === 'search' || account.sn !== replyTo.sn) {
      return false;
    }

    if (!isAccountOnRecipients(account)) {
      return false;
    }

    return true;
  };

  $rootScope.$on('selectQuotedMessage', (event, message) => {
    $scope.quotedMessage = message;
  });

  $scope.unselectQuotedMessage = () => {
    $scope.quotedMessage = null;
    $scope.$applyAsync();
  };

  function configureReply() {

    $scope.unselectQuotedMessage();
    if (typeof $scope.replyToMessage === 'string') {
      $scope.replyToMessage = $scope.$eval($scope.replyToMessage);
    }

    var kind = $scope.replyToMessage.kind;
    var sn = $scope.replyToMessage.sn;
    var isPrivate = kind === 'private';

    $scope.isPrivateReply = isPrivate;
    $scope.maxChar = getMaxChar();

    setUpDefaultReply();
  }

  function setUpDefaultReply() {
    var kind = $scope.replyToMessage.kind;
    var sn = $scope.replyToMessage.sn;
    var isPrivate = kind === 'private';

    if (!isPrivate) {
      var targetUser = getTargetUser();
      var uname = targetUser.uname;
      if (sn === 'twitter' || sn === 'instagram') {
        uname = '@' + uname;
      }
      $scope.recipientUname = uname;
      if (!$scope.text || $scope.text.indexOf(uname) === -1) {
        $scope.text = uname + ' ';
      }
    }
  }

  function isOurMessage(message) {
    return _.some(message.recipients, function (recipient) {
      return recipient.uid === message.author.uid;
    });
  }

  // returns either the user from the message being replied or the case user
  // if its the first user is ours
  function getTargetUser() {
    if (isOurMessage($scope.replyToMessage) && $scope.fallbackTargetUser) {
      return $scope.fallbackTargetUser;
    } else {
      return $scope.replyToMessage.author;
    }
  }

  $scope.$watch('replyToMessage', function (value, oldValue) {
    var isInitCall = oldValue === value;
    if (
      value &&
      (!oldValue || isInitCall || value.resource_id !== oldValue.resource_id)
    ) {
      var targetUser = getTargetUser();
      var uname = targetUser.uname;
      var isTwitterMessage = $scope.replyToMessage.sn === 'twitter';
      var username = isTwitterMessage ? '@' + uname : uname;

      $scope.cannedResponseParams = {
        name: angular.copy(targetUser.name),
        uname: angular.copy(username)
      };
      configureReply();
    }
  });

  const newMessagesListenersOff = $scope.$on('caseMessages:newMessages', function (e, lastMessage) {
    var lastMessageChanged = lastMessage.resource_id !== $scope.replyToMessage;
    const userTypedAReply =
      $scope.replyForm && $scope.replyForm.text
        ? $scope.replyForm.text.$dirty
        : false;
    var notOurs = !lastMessage.ours;
    if (lastMessageChanged && !userTypedAReply && notOurs) {
      $scope.replyToMessage = lastMessage;
      setUpDefaultReply();
    }
  });

  $scope.$on('$destroy', () => {
    saveDraft();
    newMessagesListenersOff();
  })

  function saveDraft() {
    try {
      if ($scope.text != '') {
        existingDrafts[currentCase] = {text: $scope.text};
      } else {
        delete existingDrafts[currentCase];
      }
      localStorage.setItem(ticketDrafts, JSON.stringify(existingDrafts));
    } catch {
      // do nothing
    }
  }

  $scope.cleanFiles = function (kind) {

    $scope.recordingAudio = false;
    $scope.audioRecorded = false;
    $scope.recordedAudioUrl = null;

    if (kind === 'document') {
      $scope.uploadedDocumentFiles = [];
    } else if (kind === 'image') {
      $scope.uploadedImageFile = undefined;
      $scope.imagePreview = undefined;
      $scope.maxChar = getMaxChar();
      resetElementUploadFile();
    } else {
      $scope.uploadedAudio = undefined;
      $scope.uploadedImageFile = undefined;
      $scope.imagePreview = undefined;
      $scope.uploadedDocumentFiles = [];
      $scope.maxChar = getMaxChar();
    }
    $scope.$files = undefined;
  };

  function resetElementUploadFile() {
    $scope.displayInputFile = false;
    setTimeout(() => ($scope.displayInputFile = true));
  }

  $scope.isPublishable = function () {

    if ($scope.audioRecorded && $scope.outboundAudio) {
      return true;
    } else if ($scope.audioRecorded && !$scope.outboundAudio) {
      return false;
    }

    var text = $scope.text;
    if (isEmpty(text)) {
      return false;
    }

    if (!self.selectedAccount) {
      return false;
    }

    if (text.length > $scope.maxChar) {
      return false;
    }

    var kind = $scope.replyToMessage.kind;
    var sn = self.selectedAccount.sn;

    if (sn === 'twitter' && kind !== 'private') {
      var uname = $scope.recipientUname.toLowerCase();
      if (text.toLowerCase().indexOf(uname) === -1) {
        $scope.missingTwitterTargetName = true;
        return false;
      } else {
        $scope.missingTwitterTargetName = false;
      }
    }

    if (sn === 'email') {
      if (self.recipients.length == 0) {
        return false;
      }
    }

    return true;
  };

  $scope.displayInputFile = true;

  $scope.onFileSelect = function (files, type) {
    if (files.length === 0) {
      return;
    }

    if ($scope.blockMedia) {
      var allowed = $scope.allowedExtension;
      for (var index = 0; index < files.length; index++) {
        var filename = files[index].name;
        var split = filename.split('.');
        var extension = split[split.length - 1];
        if (!allowed.includes(extension)) {
          files.splice(index, 1);
          AdNotification.error({status: 403, response: {extension}}, 'media_upload');
          if (extension === 'mp3' && $scope.audioRecorded) resetAudioRecording();
        }
      }

      if (files.length === 0) {
        return;
      }
    }

    function imageSelect(files) {
      var reader = new FileReader();
      $scope.uploadedImageFile = files[0];
      reader.readAsDataURL(files[0]);
      reader.onload = function (event) {
        $timeout(function () {
          $scope.imagePreview = event.target.result;
          // cleaning invisible input button
          $element.find('#ng-document-select-' + $scope.$id).val('');
        });
      };
      //Remove 24 char from twitter message, for add the image.
      $scope.maxChar = getMaxChar() - count_url_size;
    }

    function documentSelect(files) {
      if ($scope.canMultiAttach()) {
        $scope.uploadedDocumentFiles =
          $scope.uploadedDocumentFiles.concat(files);
      } else {
        $scope.uploadedDocumentFiles = [files[0]];
      }
      // cleaning invisible input button
      $element.find('#ng-image-select-' + $scope.$id).val('');
    }

    function audioSelect(files) {
      $scope.uploadedAudio = files[0];
    }

    switch (type) {
      case 'image':
        imageSelect(files);
        break;
      case 'document':
        documentSelect(files);
        break;
      case 'audio':
        audioSelect(files);
        break;
    }
  };

  function setupRecognitionAfterProfileLoaded() {
    if (SpeechRecognition !== null) {
      setupRecognition();
    } else {
      $scope.hasTranscription = false;
      $scope.audioTranscriptionConfidence = 0;
      $scope.audioTranscription = $filter('translate')('TRANSCRIPTION_UNSUPPORTED');
    }
  }  

  function setupRecognition() {

    recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.lang = $scope.lang;

    recognition.onresult = event => {
      $scope.audioTranscription = '';
      $scope.hasTranscription = true;
      let confidences = [];
      for (var i = event.resultIndex; i < event.results.length; i++) {
        $scope.audioTranscription += event.results[i][0].transcript;
        confidences.push(event.results[i][0].confidence);
      }
      let confidenceMean = confidences.reduce((a, b) => a + b, 0) / confidences.length;
      $scope.audioTranscriptionConfidence = confidenceMean;

    };

    recognition.onerror = () => {
      $scope.hasTranscription = false;
      $scope.audioTranscriptionConfidence = 0;
      $scope.audioTranscription = $filter('translate')('TRANSCRIPTION_UNAVAILABLE');
    };
  };

  function startRecognition() {
    $scope.audioTranscription = $filter('translate')('LOADING_TRANSCRIPTION');
    $scope.hasTranscription = false;
    $scope.audioTranscriptionConfidence = 0;
    try {
      recognition.start();
    } catch(ex) {
      $scope.audioTranscription = $filter('translate')('TRANSCRIPTION_UNSUPPORTED');
    }
  };

  function stopRecognition() {
    if (recognition) {
      recognition.stop();
    }
  };

  let stopRecording = () => {};

  $scope.toggleAudioRecording = async function () {

    if ($scope.audioRecorded) {
      resetAudioRecording();
    } else if ($scope.recordingAudio) {
      stopRecognition();
      stopRecording();
    } else {
      startRecognition();
      recordAudio();
    }
    $scope.$applyAsync();
  };

  function resetAudioRecording() {
    $scope.uploadedAudio = undefined;
    $scope.audioRecorded = false;
    $scope.hasTranscription = false;
    $scope.audioTranscriptionConfidence = 0;
    $scope.audioTranscription = $filter('translate')('LOADING_TRANSCRIPTION');
  };

  function getAudioRecordName() {
    return `audio_${$scope.user.first_name}_${moment().unix()}.mp3`;
  }

  function recordAudio() {
    try {

      $scope.recordingAudio = true;
      // New instance
      const recorder = new MicRecorder({bitRate: 128});

      // Start recording. Browser will request permission to use your microphone.
      recorder.start();

      stopRecording = () => {
        recorder.stop().getMp3().then(([buffer, blob]) => {
          // do what ever you want with buffer and blob
          // Example: Create a mp3 file and play

          blob.name = getAudioRecordName();

          let url = URL.createObjectURL(blob);
          $scope.recordedAudioUrl = $sce.trustAsResourceUrl(url);

          let audioElement = document.getElementById('audioRecording') as HTMLAudioElement;
          audioElement.src = $scope.recordedAudioUrl;
          $scope.recordingAudio = false;
          $scope.audioRecorded = true;

          $scope.onFileSelect([blob], 'audio');

        }).catch((e) => {
          console.log('error on recorder.stop():',e);
        });
      };

    } catch (error) {
      console.log('error recording audio:', error);
      AdNotification.error(403, 'microphone_access_error');
      $scope.recordingAudio = false;
    }
  };

  $scope.isCharLimited = function () {
    return $scope.maxChar !== Infinity;
  };

  $scope.checkResizeTextArea = function (e) {
    var element = angular.isObject(e) ? e.target : angular.element(e);
    element.style.height = '0px'; // This is the fix when user erases text.
    var scrollHeight = element.scrollHeight;
    element.style.height = scrollHeight + 'px';
  };

  $scope.publishIfCtrlEnter = function (e) {
    const modifier = getDeviceInfo().os === 'Mac' ? e.metaKey : e.ctrlKey;
    if (
      modifier &&
      (e.keyCode === 13 || e.keyCode === 10) &&
      $scope.isPublishable()
    ) {
      $scope.publishMessage();
    }
  };

  $scope.pasteImage = function(event) {
    function getImageFromClipboard(evt) {
      const items = evt.originalEvent.clipboardData?.items;
      for (let i=0; i<items.length; i++) {
        const item = items[i];
        if (item.type.indexOf('image') !== -1) {
          const blob = item.getAsFile();
          return blob;
        }
      }
      return null;
    }
    const imageBlob = getImageFromClipboard(event);
    if (imageBlob) {
      event.preventDefault();
      const canAttachImage = !$scope.isPrivateReply
        || $scope.replyToMessage.sn === 'facebook'
        || $scope.replyToMessage.sn === 'instagram';
      const attachmentType = canAttachImage ? 'image' : 'document';
      $scope.onFileSelect([imageBlob], attachmentType);
    }
  }

  $scope.getHotkeyActivator = function () {
    return getDeviceInfo().os === 'Mac' ? 'Cmd' : 'Ctrl';
  };

  $scope.isEmail = function () {
    return $scope.replyToMessage.sn === 'email';
  };

  $scope.successFn = function (url) {
    if ($scope.text) {
      $scope.text += '\n';
    }
    $scope.text += url;
    AdNotification.success(200, file_upload_action);
  };

  $scope.errorFn = function (exception) {
    AdNotification.error('error', file_upload_action);
  };

  $scope.discardMessage = function () {
    $scope.showControls = false;
    $scope.cleanFiles();
    $scope.text = '';
    setUpDefaultReply();
    $scope.cannedResponseContextCtrl.suggestionsActive = false;
    $scope.discardCallback();
  };

  $scope.copyTranscription = function () {
    $scope.text = $scope.audioTranscription;
  };

  $scope.deleteDocumentFile = function (index) {
    $scope.uploadedDocumentFiles.splice(index, 1);
    resetElementUploadFile();
  };

  $scope.getFilenameSansExtension = function (filename) {
    var lastDotIndex = filename.lastIndexOf('.');
    return lastDotIndex > 0 ? filename.slice(0, lastDotIndex) : filename;
  };

  $scope.getFilenameExtension = function (filename) {
    var lastDotIndex = filename.lastIndexOf('.');
    return lastDotIndex > 0 ? filename.slice(lastDotIndex) : '';
  };

  $scope.publishMessage = function () {
    if ($scope.loading) {
      return;
    }
    // enviar mensaje privado: lada : loading
    $scope.loading = true;

    const sn = $scope.replyToMessage.sn;
    const replyParameters = {
        httpConfig: {},
        formData: null,
        sn: sn,
        provider: (function (){
            if (sn == 'whatsapp') {
              return self.selectedAccount.config.whatsapp_provider;
            }
            return sn;
        })()
    }
    var selectedAccount = {
      uid: self.selectedAccount.uid,
      sn: self.selectedAccount.sn
    };

    function sendDmTo(replyTo) {
      var author = replyTo.author.uid;
      var recipients = replyTo.recipients;
      var sendToRecipient = _.find(recipients, function (recipient) {
        return recipient.uid === author;
      });

      if (sendToRecipient) {
        return replyTo['recipient_id'];
      }
      return replyTo.author.uid;
    }

    var messageData = {
      text: $scope.text && $scope.text.trim(),
      social_accounts: [selectedAccount],
      case_id: $rootScope.caseId,
      is_private: false
    };
    if ($scope.isEmail()) {
      messageData.raw_text = getPlainText();
    }
    var hasImage = $scope.uploadedImageFile !== undefined;
    var hasDocument = $scope.uploadedDocumentFiles.length > 0;
    var hasAudio = $scope.uploadedAudio !== undefined;
    var hasFiles = hasImage || hasDocument || hasAudio;

    if (hasAudio && $scope.hasTranscription) {
      messageData['transcription'] = {
        text: $scope.audioTranscription,
        confidence: $scope.audioTranscriptionConfidence
      };
    };

    if ($scope.isPrivateReply) {
      action = 'dm';

      if (sn === 'twitter') {
        messageData['sending_to_users'] = [sendDmTo($scope.replyToMessage)];
      } else if (sn === 'whatsapp') {
        messageData['sending_to_users'] = [$scope.replyToMessage.thread_root];
      } else if (sn === 'email') {
        messageData['sending_to_users'] = self.recipients;
        messageData['reply_to_msg'] = $scope.replyToMessage.mid;
      } else {
        messageData['reply_to_msg'] = $scope.replyToMessage.mid;
      }
      messageData['is_private'] = true;
    } else {
      action = 'reply';
      messageData['reply_to_msg'] = $scope.replyToMessage.mid;
    }

    if ($scope.quotedMessage) {
      messageData['quoted_message'] = {'mid':$scope.quotedMessage.mid}
    };

    // This is important to do AFTER setting everything on messageData
    // because it will be converted to json FormData.

    if (hasFiles) {
      const jsonData = angular.copy(messageData);

      const formData = new FormData();
      formData.append('json', angular.toJson(jsonData));

      if (hasImage) {
        var filename = $scope.uploadedImageFile.name;
        filename = AderesoFilename.slugFileName(filename);
        formData.append('image', $scope.uploadedImageFile, filename);
      }

      angular.forEach($scope.uploadedDocumentFiles, function (file) {
        filename = AderesoFilename.slugFileName(file.name);
        formData.append('document', file, file.name);
      });

      if (hasAudio) {
        formData.append('document', $scope.uploadedAudio, getAudioRecordName());
      }

      replyParameters.httpConfig = {
        headers: {'Content-Type': undefined},
        transformRequest: angular.identity
      };
      replyParameters.formData = formData;
    }

    $scope.isSending = true;

    (() => messageData.is_private ?
            messageService.privateReply(messageData, replyParameters) :
            messageService.publicReply(messageData, replyParameters)
    )().then(message => {
          replyMessageGet();
          if ($scope.summernoteText) {
            $('#summernoteReply').summernote('reset');
          } else {
            clearAfterPublish();
          }

          if ($scope.publishCallback) {
            $scope.publishCallback(message);
          }
          AdNotification.success(201, action);
          FireTagService.setEvent({
            name: 'responde_ticket',
            params: {
              channel_name: $scope.replyToMessage.sn || ''
            }
          });
        },
        data => {
          const {response} = data;
          if (response && typeof response === 'object') {
            const {third_party_http_code: code, detail} = response;
            if (
              detail.sn === 'facebook' ||
              detail.sn === 'twitter' ||
              detail.sn === 'instagram'
            ) {
              const {sn, code, subcode} = detail;
              const {language: locale} = $scope.user.profile;
              const errorMessages = snErrorMessage.getMessage({
                sn,
                code,
                subcode,
                locale
              });
              AdNotification.notify(errorMessages, 'error');
            } else if (code !== undefined && code === 100) {
              $rootScope.$emit('message:isDeleted', messageData);
            } else {
              AdNotification.error(data, action);
            }
          } else {
            AdNotification.error(data, action);
          }
        }
      )
      .then(() => {
        // success or error: boton enviar mensaje habilitado nuevamente
        $scope.loading = false;
        $scope.isSending = false;
        resetElementUploadFile();
      });
  };
  const replyMessageGet = () => {
    const resourceId = $scope.replyToMessage['resource_id'];
    messageService.get(resourceId).then(function (replyToMessage) {
      const emitData = {action: 'reply', element: replyToMessage};
      $rootScope.$emit('column:actionOnMessage', emitData);
      $.extend($scope.replyToMessage, replyToMessage);
    });
  };
}

angular
  .module('postCenterWebClientApp')
  .controller('ReplyMessageCtrl', ReplyMessageCtrl)
