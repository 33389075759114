interface salesforceCaseEntity {
  Id: string
  ContactId: string | null
  ContactEmail: string | null
  ContactPhone: string | null
  CaseNumber: string | null
  Subject: string | null
  Description: string | null
  Comments: string | null
  CreatedDate: string
  IsClosed: boolean
  Priority: string
  Status: string
  Reason: string
  Origin: string
}

interface hubspotCaseProperties {
  hs_ticket_id: string
  createdate: string
  subject: string
  content: string | null
  hs_ticket_priority: string | null
  source_type: string | null
  hs_resolution: string | null
  hs_ticket_category: string | null
}

interface hubspotCaseEntity {
  properties: hubspotCaseProperties
}

type crmCaseEntity = hubspotCaseEntity | salesforceCaseEntity | null;

function isHubspotCase(object: any): object is hubspotCaseEntity {
  return 'properties' in object;
}

function isSalesforceCase(object: any): object is hubspotCaseEntity {
return 'CaseNumber' in object;
}

class CaseMetadataCtrl {

  private AdNotification
  private UserService: UserService
  private EstablishmentService: EstablishmentService
  private CustomData: CustomDataService
  private CrmService: CrmService
  private CaseDetailsService
  private crmNames = ['SALESFORCE', 'HUBSPOT', 'SUGARCRM']

  $rootScope
  $scope
  $filter
  caseObject
  userObject
  departmentWatch
  crmActive = null
  pendingCrmSave = false;
  CrmIntegrationSlideOpen = false;
  loadingCrmEntity = true;
  crmCaseEntity = null;
  crmProperties = [];

  $onInit() {
    this.setInfoObjects();
    this.init();
  }

  init() {
    Promise.all([
      this.UserService.getProfile(),
      this.CustomData.getCustomData(),
      this.EstablishmentService.getEstablishment()
    ]).then(results => {
      let user: ExtendedUser = results[0]
      let customData: CustomData[] = results[1]
      let establishment: Establishment = results[2]
      this.$scope.canInteract = user.isAdminOrCm
      this.$scope.saving = false;
      this.$scope.showSaveTooltip = false;
      this.$scope.caseCustomData = customData
        .filter(cd => cd.entity === 'C' && !cd.deleted)
        .sort((a, b) => a.index - b.index)
      this.crmActive = establishment.integrations.integration_configs.find(
          ({config: {active}, name}) => !!(active && this.crmNames.includes(name))
        ) || null;
    })
  }

  setInfoObjects() {
    this.userObject = this.userObject || this.CaseDetailsService.get('userObject')
    this.caseObject = this.caseObject || this.CaseDetailsService.get('caseObject')
  }

  $onDestroy() {
    this.departmentWatch()
  }

  getCrmName(): string | null {return this.hasCrmActive() ? this.crmName() : null};
  getCrmIcon(): string | null {return this.hasCrmActive() ? `fa-${this.crmName()}` : null};
  getCrmLogo(): string | null {return this.hasCrmActive() ? this.crmLogo() : null};
  getCrmIconTooltip(): string | null {
    return this.$filter('translate')('CRM_SEE_ENTITY_ON', {
      entity: this.$filter('translate')('case'),
      crmName: this.crmName()
    })
  };

  hasCrmActive(): boolean {
    const {crm_integration: caseCrmIntegration} = this.caseObject;
    return (
      this.crmActive &&
      caseCrmIntegration &&
      caseCrmIntegration[this.getCrmkey()]
    );
  };

  getEntityId(entity): string {
    const {crm_integration} = entity;
    return crm_integration[this.getCrmkey()];
  };

  getCrmkey(): string {
    return `${this.crmName()}_id`;
  };

  crmName(): string {
    const {name = ''} = this.crmActive || {};
    return name.toLowerCase();
  };

  capitalizeCrmName(): string {
    const {name = ''} = this.crmActive || {};
    return name === 'SALESFORCE' ? 'Salesforce' : 'HubSpot'
  };

  crmLogo(): string {
    const {name = ''} = this.crmActive || {};
    if (name == 'HUBSPOT') {
      return 'https://cdn.adere.so/images/desk/integrations/crm/hubspot/hubspot.png'
    } else if (name == 'SALESFORCE') {
      return 'https://cdn.adere.so/images/desk/integrations/crm/salesforce/salesforce.png'
    }
  };

  openCrmIntegrationWindow() {
    if (this.CrmIntegrationSlideOpen) {
      this.CrmIntegrationSlideOpen = false;
    } else {
      this.getCaseFromCrm();
      this.CrmIntegrationSlideOpen = true;
    }      
  };

  getDateValue(date: string | null): Date | null {
    if (date) return new Date(date);
    return null;
  }

  async unlinkEntity() {
    await this.CrmService.unlinkEntity(this.crmName(), 'case', this.caseObject.resource_id);
    this.caseObject.crm_integration[this.getCrmkey()] = null;
    this.crmCaseEntity = null;
    this.crmProperties = [];
    this.CrmIntegrationSlideOpen = false;
    this.$scope.$apply();
    this.$onInit();
  };

  async getCaseFromCrm() {
    this.loadingCrmEntity = true;
    try {
      const getEntityResponse = await this.CrmService.getEntity(this.crmName(), 'case', this.getEntityId(this.caseObject))
      this.crmCaseEntity = getEntityResponse;
      this.crmProperties = this.getCRMProperties(getEntityResponse);
    } catch (error) {
      this.AdNotification.error(error, 'get_crm_case');
      this.crmCaseEntity = null;
      this.crmProperties = [];
    } finally {
      this.loadingCrmEntity = false;
    }
  };

  getCRMProperties(crmCaseEntity: crmCaseEntity): Array<CrmProperty> {

    let defaultCrmProperties: Array<CrmProperty> = [];

    if (isHubspotCase(crmCaseEntity)) {
      let properties: hubspotCaseProperties = crmCaseEntity.properties;
      defaultCrmProperties = [
        {
          fieldname: 'hs_ticket_id',
          type: 'text',
          translation: this.$filter('translate')('TICKET_ID'),
          value: properties.hs_ticket_id,
          editable: false
        },
        {
          fieldname: 'createdate',
          type: 'date',
          translation: this.$filter('translate')('TICKET_CREATED_DATE'),
          value: this.getDateValue(properties.createdate),
          editable: false
        },
        {
          fieldname: 'subject',
          type: 'text',
          translation: this.$filter('translate')('TICKET_SUBJECT'),
          value: properties.subject,
          editable: true
        },
        {
          fieldname: 'content',
          type: 'text',
          translation: this.$filter('translate')('TICKET_DESCRIPTION'),
          value: properties.content,
          editable: true
        },
        {
          fieldname: 'hs_ticket_priority',
          type: 'text',
          translation: this.$filter('translate')('TICKET_PRIORITY'),
          value: properties.hs_ticket_priority,
          editable: true
        },
        {
          fieldname: 'hs_resolution',
          type: 'text',
          translation: this.$filter('translate')('TICKET_STATUS'),
          value: properties.hs_resolution,
          editable: true
        },
        {
          fieldname: 'source_type',
          type: 'text',
          translation: this.$filter('translate')('TICKET_ORIGIN'),
          value: properties.source_type,
          editable: true
        },
        {
          fieldname: 'hs_ticket_category',
          type: 'text',
          translation: this.$filter('translate')('TICKET_CATEGORY'),
          value: properties.hs_ticket_category,
          editable: true
        }
      ]
    } else if (isSalesforceCase(crmCaseEntity)) {
      defaultCrmProperties = [
        {
          fieldname: 'Id',
          type: 'text',
          translation: this.$filter('translate')('SALESFORCE_ID'),
          value: crmCaseEntity.Id,
          editable: false
        },
        {
          fieldname: 'CaseNumber',
          type: 'text',
          translation: this.$filter('translate')('TICKET_ID'),
          value: crmCaseEntity.CaseNumber,
          editable: false
        },
        {
          fieldname: 'CreatedDate',
          type: 'date',
          translation: this.$filter('translate')('TICKET_CREATED_DATE'),
          value: this.getDateValue(crmCaseEntity.CreatedDate),
          editable: false
        },
        {
          fieldname: 'IsClosed',
          type: 'text',
          translation: this.$filter('translate')('TICKET_IS_CLOSED'),
          value: crmCaseEntity.IsClosed,
          editable: false
        },
        {
          fieldname: 'ContactId',
          type: 'text',
          translation: this.$filter('translate')('CONTACT_ID'),
          value: crmCaseEntity.ContactId,
          editable: false
        },
        {
          fieldname: 'ContactEmail',
          type: 'text',
          translation: this.$filter('translate')('CONTACT_EMAIL'),
          value: crmCaseEntity.ContactEmail,
          editable: false
        },
        {
          fieldname: 'ContactPhone',
          type: 'text',
          translation: this.$filter('translate')('CONTACT_PHONE'),
          value: crmCaseEntity.ContactPhone,
          editable: false
        },
        {
          fieldname: 'Subject',
          type: 'text',
          translation: this.$filter('translate')('TICKET_SUBJECT'),
          value: crmCaseEntity.Subject,
          editable: true
        },
        {
          fieldname: 'Description',
          type: 'text',
          translation: this.$filter('translate')('TICKET_DESCRIPTION'),
          value: crmCaseEntity.Description,
          editable: true
        },
        {
          fieldname: 'Comments',
          type: 'text',
          translation: this.$filter('translate')('TICKET_COMMENTS'),
          value: crmCaseEntity.Comments,
          editable: true
        },
        {
          fieldname: 'Status',
          type: 'text',
          translation: this.$filter('translate')('TICKET_STATUS'),
          value: crmCaseEntity.Status,
          editable: true
        },
        {
          fieldname: 'Reason',
          type: 'text',
          translation: this.$filter('translate')('TICKET_REASON'),
          value: crmCaseEntity.Reason,
          editable: true
        },
        {
          fieldname: 'Priority',
          type: 'text',
          translation: this.$filter('translate')('TICKET_PRIORITY'),
          value: crmCaseEntity.Priority,
          editable: true
        },
        {
          fieldname: 'Origin',
          type: 'text',
          translation: this.$filter('translate')('TICKET_ORIGIN'),
          value: crmCaseEntity.Origin,
          editable: true
        }
      ]
    }
    let defaultCrmFields: Array<string> = defaultCrmProperties.map(function(element){
      return element.fieldname;
    });
    let customCrmProperties: Array<CrmProperty> = this.getCustomCrmProperties(crmCaseEntity, defaultCrmFields);
    return [...defaultCrmProperties, ...customCrmProperties];
  };

  getCustomCrmProperties(crmCaseEntity, defaultCrmFields: Array<string>): Array<CrmProperty> {
    let customCrmProperties: Array<CrmProperty> = []
    this.$scope.caseCustomData.forEach(e => {
      if (e.crm_integration) {
        let integration_key: string = e.crm_integration[this.getCrmkey()];
        if (integration_key && !defaultCrmFields.includes(integration_key)) {
          let value = null;
          if (isHubspotUser(crmCaseEntity)) {
            value = crmCaseEntity.properties[integration_key]
          } else if (isSalesforceUser(crmCaseEntity)){
            value = crmCaseEntity[integration_key]
          }
          customCrmProperties.push({
            fieldname: integration_key,
            type: 'text',
            translation: e.label,
            value: value,
            editable: true
          })
        }
      }
    });
    return customCrmProperties;
  };

  addContactIdToUpdate(update: crmUpdate): crmUpdate {
    let contactEntityId = this.getEntityId(this.userObject)
    const {name = ''} = this.crmActive || {};
    if (name == 'SALESFORCE') {
      if (contactEntityId) {
        update['ContactId'] = contactEntityId;
      }
    }
    return update;
  }

  getCrmUpdate(crmProperties: Array<CrmProperty>): crmUpdate {
    let crmUpdate: crmUpdate = {}
    crmProperties.forEach(p => {
      if (p.editable) {
        crmUpdate[p.fieldname] = p.value;
      }
    });

    crmUpdate = this.addContactIdToUpdate(crmUpdate);
    return crmUpdate
  }

  async saveCrmProperties(crmProperties: Array<CrmProperty>) {
    this.loadingCrmEntity = true;
    let crmUpdate: crmUpdate = this.getCrmUpdate(crmProperties);
    try {
      await this.CrmService.saveEntity(this.crmName(), 'case', this.getEntityId(this.caseObject), crmUpdate);
      this.AdNotification.success(200, 'save_crm_properties');
      this.pendingCrmSave = false;
    } catch (error) {
      this.AdNotification.error({}, 'save_crm_properties');
    }
    this.loadingCrmEntity = false;
  };

  constructor(
    $rootScope,
    $scope,
    $filter,
    AdNotification,
    UserService: UserService,
    EstablishmentService: EstablishmentService,
    CustomData: CustomDataService, 
    CrmService: CrmService,
    CaseDetailsService
  ) {

    this.$rootScope = $rootScope
    this.$scope = $scope
    this.$filter = $filter
    this.AdNotification = AdNotification
    this.UserService = UserService
    this.EstablishmentService = EstablishmentService
    this.CustomData = CustomData
    this.CrmService = CrmService
    this.CaseDetailsService = CaseDetailsService

    const caseUpdateListenerOff = $scope.$on('case:hasBeenUpdated', (event, caseObject) => {
      this.caseObject = caseObject
      this.init();
    })

    const customDataSaveOff = $scope.$on('customDataBlock:saveDone', () => {
      this.$scope.saving = false;
      $rootScope.$emit(
        'integration:window:refresh_case',
        angular.fromJson(this.caseObject)
      );
      const custom_fields = this.caseObject.custom_fields || [];
      this.caseObject.has_custom_data = custom_fields.length !== 0;
      $rootScope.$emit('column:actionOnCase', {
        action: 'updateCase',
        element: this.caseObject
      })

      $rootScope.$emit('caseMetadata:hasBeenUpdated');
    })

    $scope.$on('$destroy', () => {
      caseUpdateListenerOff();
      customDataSaveOff();
    })

    this.departmentWatch = $scope.$watch(
      'this.caseObject.department_id',
      newValue => {
        $scope.$broadcast('customDataBlock:caseObject', this.caseObject);
      }
    )
  }
};

angular
  .module('postCenterWebClientApp')
  .controller('caseMetadataCtrl', CaseMetadataCtrl)
